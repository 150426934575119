/* eslint-disable no-extend-native */
export const sleep = (delay: number): Promise<any> => new Promise((resolve) => {
  setTimeout(resolve, delay);
});

/**
 * 限制promise的最快返回时间, 避免接口过快导致闪屏
 */
Object.defineProperty(Promise.prototype, 'atleast', {
  async value(ms: number) {
    if (!(this instanceof Promise)) throw new Error('');

    const start = Date.now();

    const result = await this;

    const cost = Date.now() - start;

    if (cost < ms) await sleep(ms - cost);

    return result;
  },
  enumerable: false,
  writable: false,
});

const dayMM = 1000 * 60 * 60 * 24;
export const getDayCount = (dayInfo: string): number => {
  const expireTime = Date.parse(dayInfo);
  const currTime = Date.now();
  const restDays = (expireTime - currTime) / dayMM;
  return Math.ceil(restDays);
};

// 获取url上的参数
export const getQueryVariable = (variable: string): string => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (const iterator of vars) {
    const pair = iterator.split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return '';
};

// 删除url中某个参数,并跳转
export const delUrlParam = (paramKeys: string[]): string => {
  let url = window.location.href; // 页面url
  const urlParam = window.location.search.substr(1); // 页面参数
  const beforeUrl = url.substr(0, url.indexOf('?')); // 页面主地址（参数之前地址）
  let nextUrl = '';

  const arr = [];
  if (urlParam !== '') {
    const urlParamArr = urlParam.split('&'); // 将参数按照&符分成数组
    for (const iterator of urlParamArr) {
      const paramArr: string[] = iterator.split('='); // 将参数键，值拆开
      if (!paramKeys?.includes(paramArr[0])) {
        arr.push(iterator);
      }
    }
  }
  if (arr.length > 0) {
    nextUrl = `?${arr.join('&')}`;
  }
  url = beforeUrl + nextUrl;
  return url;
};

export const isRedirectMobile = /Mobi|Android|iPhone/i.test(navigator.userAgent) && /^\/pc{1,}/.test(window.location.pathname);

export default { sleep, getQueryVariable, delUrlParam, isRedirectMobile };
