export enum STATUS {
  WAITING,
  PENDING,
  FULFILLED,
  REJECTED
}

type CallbackType = (data: any) => void;
const UNSET_CHANGE_FULFILLED_TIPS = 'async-wait-callbacks使用错误。需要先注册更改status的方法再注入回调函数';

/**
 * 异步依赖状态机
 * 多个回调依赖某个异步操作时，通过此类进行操作
 * WAITING: 存放回调至fulfilledCallbacks，等待FULFILLED时执行，执行被依赖的异步函数，状态由 WAITING 转移为 PENDING
 * PENDING: 等待被依赖的异步函数执行完毕，继续存放回调至fulfilledCallbacks。被依赖函数执行后，状态由 PENDING 转移为 FULFILLED
 * FULFILLED: 将上一步依赖函数的执行结果保存，并批量执行fulfilledCallbacks存放的函数。清空callbacks，防止重复执行，后续的回调将直接执行
 * REJECTED: 执行changeStatus失败，并批量执行rejectedCallbacks存放的函数。清空callbacks，防止重复执行，后续的回调将直接执行
 * 重新设置被依赖的回调函数时会将状态转移为WAITING
 *
 * 效果
 * 类似document.onload操作，但能保证不管什么阶段进行回调，被依赖的接口都只处理一次
 * 或者用于解决回调和promise结合的场景(js bridge)
 * 注意
 * setChangeStatus必须在onFulfilledCallback前使用，否则会调用默认changeStatus方法
 * 默认为无异步依赖模式，无异步依赖则不应该使用此类，会有throw error
 */
export class AsyncWaitCallback {
  private status: STATUS; // 当前状态

  private fulfilledCallbacks: Array<CallbackType>; // fulfilled时执行的回调函数数组

  private rejectedCallbacks: Array<CallbackType>; // fulfilled时执行的回调函数数组

  private data: unknown | undefined; // 异步函数的执行结果，用于执行回调函数的参数

  private changeStatus: () => Promise<unknown>; // 被依赖的异步函数

  private changeStatusError: Error | undefined; // changeStatus报错时，需要记录错误

  public constructor() {
    // 初始化所需成员
    this.status = STATUS.WAITING;
    this.fulfilledCallbacks = [];
    this.rejectedCallbacks = [];

    // 必须设置改变状态fulfilled的回调函数，否则不该使用该类
    this.changeStatus = () => {
      throw new Error(UNSET_CHANGE_FULFILLED_TIPS);
    };
  }

  /**
   * 此方法结束才触发STAGE变更为fulfilled或rejected
   * 处于PENDING状态下，不允许重置status
   */
  public setChangeStatus(changeStatus: () => Promise<unknown>): void {
    if (this.status !== STATUS.PENDING) {
      this.status = STATUS.WAITING;
      // eslint-disable-next-line no-return-await
      this.changeStatus = async () => await changeStatus();
    }
  }

  public getStatus(): STATUS {
    return this.status;
  }

  public getFulfilledCallbacks(): Array<CallbackType> {
    return this.fulfilledCallbacks;
  }

  public getRejectedCallbacks(): Array<CallbackType> {
    return this.rejectedCallbacks;
  }

  // 使用的callback函数皆在STAGE.FULFILLED才会调用
  public onEndCallback(fulfilledCallback: CallbackType, rejectedCallback: CallbackType): void {
    if (this.status === STATUS.WAITING) {
      this.status = STATUS.PENDING;

      this.fulfilledCallbacks.push(fulfilledCallback);
      this.rejectedCallbacks.push(rejectedCallback);
      this.changeStatus()
        .then((data) => {
          this.status = STATUS.FULFILLED;
          this.data = data;
          this.fulfilledCallbacks.forEach(callback => callback(data));
        })
        .catch((err) => {
          this.status = STATUS.REJECTED;
          this.changeStatusError = err;
          this.rejectedCallbacks.forEach((callback) => {
            callback(err);
          });
        })
        .finally(() => {
          // 清空数据，防止重复调用
          this.fulfilledCallbacks = [];
          this.rejectedCallbacks = [];
        });
    } else if (this.status === STATUS.PENDING) {
      this.fulfilledCallbacks.push(fulfilledCallback);
      this.rejectedCallbacks.push(rejectedCallback);
    } else if (this.status === STATUS.REJECTED) {
      rejectedCallback?.(this.changeStatusError);
    } else {
      fulfilledCallback?.(this.data);
    }
  }
}
