import Cookies from 'js-cookie';
import { storage } from '@shared/tandon-plugin/storage';
import { reportCommonEvent } from '@shared/tandon-plugin/aegis';
import { redirectToNoAuthPage } from '../interceptors/auth';
import { weworkRefreshToken } from '../api';

declare global {
  interface Window {
    isRefreshing: boolean;
    requests: any[];
  }
}

export const TANDON_REFRESH_TOKEN = 'TANDON_REFRESH_TOKEN';

export async function refreshToken(service: Function, response: any): Promise<any> {
  const refreshTokenStr = storage.local.get(TANDON_REFRESH_TOKEN);
  const { isRefreshing } = window;
  reportCommonEvent({
    event: 'REFRESH TOKEN',
    data: {
      refreshTokenStr,
      response,
    },
  });
  if (!isRefreshing) {
    // 当前没有进行刷新token操作
    window.isRefreshing = true;
    if (refreshTokenStr) {
      try {
        const res = await weworkRefreshToken(refreshTokenStr);
        reportCommonEvent({
          event: 'REFRESH TOKEN RESULT',
          data: {
            res,
          },
        });
        window.isRefreshing = false;
        if (res.Code === 0) {
          const { AccessToken, RefreshToken } = res.Data;
          // 重新设置local中的RefreshToken
          storage.local.set(TANDON_REFRESH_TOKEN, RefreshToken);
          // 重新设置cookie中的tandon_jwt
          Cookies.set('tandon_jwt', AccessToken);
          response.config.headers.Authorization = `Bearer ${AccessToken}`;
          // token 刷新后将数组的方法重新执行
          window?.requests?.forEach(cb => cb(AccessToken));
          window.requests = []; // 重新请求完清空
          return service(response.config);
        }
        // 续期失败重新设置cookie中的tandon_jwt为空
        Cookies.set('tandon_jwt', '');
        storage.local.remove(TANDON_REFRESH_TOKEN);
        // 续期失败，说明长token也过期了，此时重试请求队列里，没有短token参数，使其过期。跳扫码弹窗
        window?.requests?.forEach(cb => cb());
        window.requests = []; // 重新请求完清空
        redirectToNoAuthPage();
        return Promise.reject(new Error(res.Message));
      } catch (error) {
        window.isRefreshing = false;
        // 续期失败重新设置cookie中的tandon_jwt为空
        Cookies.set('tandon_jwt', '');
        storage.local.remove(TANDON_REFRESH_TOKEN);
        // 续期失败，说明长token也过期了，此时重试请求队列里，没有短token参数，使其过期。跳扫码弹窗
        window?.requests.forEach(cb => cb());
        window.requests = []; // 重新请求完清空
        redirectToNoAuthPage();
      }
    }
    redirectToNoAuthPage();
    return Promise.reject(new Error('登录态失效'));
  }
  // 当前正处于刷新token过程中
  // 返回未执行 resolve 的 Promise
  return new Promise((resolve) => {
    // 用函数形式将 resolve 存入，等待刷新后再执行
    if (!window.requests) {
      window.requests = [];
    }
    window.requests.push((accessToken: string) => {
      response.config.headers.Authorization = `Bearer ${accessToken}`;
      resolve(service(response.config));
    });
  });
}

export function generateRandomString(length: number): string {
  const letters = 'abcdef0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += letters.charAt(Math.floor(Math.random() * letters.length));
  }
  return result;
}
