import { v4 as uuidv4 } from 'uuid';
import { reportCommonEvent } from '@shared/tandon-plugin/aegis';
import { getWeworkCorpid } from '../token';
import { getSignatureAppPost, getSignatureCompanyPost } from './api';

// 初始化企微sdk配置
export const initWxConfig = async (jsApiList: string[] = [], href = ''): Promise<boolean> => {
  try {
    const locationHref = (href || window.location.href).split('#');
    const pageUrl = locationHref && locationHref.length > 0 ? locationHref[0] : '';
    const authCorpId = getWeworkCorpid();

    const timestamp = Math.ceil(new Date().getTime() / 1000);
    const nonceStr = uuidv4();
    const appSignatureReq = getSignatureAppPost({
      Timestamp: timestamp,
      NonceStr: nonceStr,
      PageUrl: pageUrl,
      AuthCorpId: authCorpId,
    });

    if (/MicroMessenger/i.test(navigator.userAgent)) {
      const timestamp = Math.ceil(new Date().getTime() / 1000);
      const nonceStr = uuidv4();
      const companySignatureInfo = await getSignatureCompanyPost({
        Timestamp: timestamp,
        NonceStr: nonceStr,
        AuthCorpId: authCorpId,
        PageUrl: pageUrl,
      });
      if (companySignatureInfo.Code === 0) {
        reportCommonEvent({
          event: 'WECHAT SUCCESS getSignatureCompanyPost',
          data: {
            timestamp,
            nonceStr,
            authCorpId,
            pageUrl,
          },
        });
        await initConfig({ timestamp, nonceStr, signature: companySignatureInfo.Data.Signature }, jsApiList);
      }
    }

    const signatureInfo = await appSignatureReq;
    if (signatureInfo.Code === 0) {
      reportCommonEvent({
        event: 'WECHAT SUCCESS getSignatureAppPost',
        data: {},
      });
      await agentConfig({
        corpid: authCorpId,
        agentid: signatureInfo.Data.AgentId,
        timestamp,
        nonceStr,
        signature: signatureInfo.Data.Signature,
      }, jsApiList);
    }
    return true;
  } catch (error) {
    reportCommonEvent({
      event: 'WECHAT ERROR initWxConfig',
      data: {
        jsApiList,
        error,
      },
    });
    return false;
  }
};

// 获取app应用的AgentId
export const getAppAgentId = async () => {
  const timestamp = Math.ceil(new Date().getTime() / 1000);
  const nonceStr = uuidv4();
  const locationHref = window.location.href.split('#');
  const pageUrl = locationHref && locationHref.length > 0 ? locationHref[0] : '';
  const authCorpId = getWeworkCorpid();
  const signatureInfo = await getSignatureAppPost({
    Timestamp: timestamp,
    NonceStr: nonceStr,
    PageUrl: pageUrl,
    AuthCorpId: authCorpId,
  });
  return signatureInfo;
};

export const initConfig = (params: any, jsApiList: string[]) => new Promise((resolve, reject) => {
  window.wx.config({
    beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: getWeworkCorpid(), // 必填，企业微信的corpID
    timestamp: params.timestamp, // 必填，生成签名的时间戳
    nonceStr: params.nonceStr, // 必填，生成签名的随机串
    signature: params.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
    jsApiList, // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
  });
  window.wx.ready(() => {
    reportCommonEvent({
      event: 'WECHAT SUCCESS initConfig',
      data: {},
    });
    resolve(true);
  });
  window.wx.error((err: any) => {
    reportCommonEvent({
      event: 'WECHAT ERROR initConfig',
      data: {
        params,
        jsApiList,
        err,
      },
    });
    reject(err);
  });
});

const agentConfig = (params: any, jsApiList: string[]) => new Promise((resolve, reject) => {
  reportCommonEvent({
    event: 'WECHAT CHECK agentConfig',
    data: {
      agentConfig: typeof (window.wx.agentConfig),
    },
  });
  window.wx.agentConfig({
    ...params,
    jsApiList, // 必填，传入需要使用的接口名称
    success() {
      reportCommonEvent({
        event: 'WECHAT SUCCESS agentConfig',
        data: {},
      });
      // @ts-ignore
      window.WWOpenData.bind(document.querySelector('ww-open-data'));
      // @ts-ignore
      window.WWOpenData.bindAll(document.querySelectorAll('ww-open-data'));
      setTimeout(() => {
        // @ts-ignore
        window.WWOpenData.bind(document.querySelector('ww-open-data'));
        // @ts-ignore
        window.WWOpenData.bindAll(document.querySelectorAll('ww-open-data'));
      }, 1000);
      // 回调
      resolve(true);
    },
    fail(err: any) {
      reportCommonEvent({
        event: 'WECHAT ERROR agentConfig',
        data: {
          params,
          jsApiList,
          err,
        },
      });
      if (err.errMsg.indexOf('function not exist') > -1) {
        // eslint-disable-next-line no-alert
        alert('当前企业微信版本过低，请尝试升级后访问');
      }
      reject(new Error('fail'));
    },
  });
});

export * from './api';
