import React, { useEffect, useState, useMemo } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Button, Dialog } from 'tdesign-react';
import { getWeworkCorpid, getWeworkOpenUserId, getWeworkTandonUserId } from '@shared/tandon-plugin/token';
import { ReportModule, reportFrontData } from '@shared/tandon-plugin/report';
import { ReactComponent as Rectangle } from '@/assets/svg/Rectangle.svg';
import { ReactComponent as CustomerPull } from '@/assets/svg/customer-pull.svg';
import { ReactComponent as BadCase } from '@/assets/svg/bad-case.svg';
import { ReactComponent as Lean } from '@/assets/svg/lean.svg';
import processImg from '@/assets/png/process.png';
import tencentCloudMiniLogo from '@/assets/png/tencent-cloud-mini-logo.png';
import workWxMiniLogo from '@/assets/png/work-wx-mini-logo.png';
import tencentConferenceMiniLogo from '@/assets/png/tencent-conference-mini-logo.png';
import tapdMiniLogo from '@/assets/png/tapd-mini-logo.png';
import wxPayMiniLogo from '@/assets/png/wx-pay-mini-logo.png';
import { getDescribeTenant } from '@/service/tenant';
import { ServiceStatusEnum, STUFF_URL } from '@/constants';
import DialogContent from '@/components/dialog-content';
import { MessageError } from '@shared/tandon-design/message-error';
import { reportCustomEvent } from '@shared/tandon-plugin/aegis';
import feat001 from '@/assets/png/feat001.png';
import feat002 from '@/assets/png/feat002.png';
import feat003 from '@/assets/png/feat003.png';
import feat004 from '@/assets/png/feat004.png';
import feat005 from '@/assets/png/feat005.png';
import feat006 from '@/assets/png/feat006.png';
import feat007 from '@/assets/png/feat007.png';
import feat008 from '@/assets/png/feat008.png';
import CaseScroll from './components/case-scroll';
// import Tabs from './components/tabs';
import LoadingPage from '../loading-page';
import s from './index.module.scss';

export default function LandingPage(): React.ReactElement {
  const [visible, setVisible] = useState(false);
  const [serviceStatus, setServiceStatus] = useState<number>(0);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  useEffect(() => {
    getDescribeTenant({
      CorpId: getWeworkCorpid(),
      Page: 1,
      PageSize: 1,
    })
      .then((rs) => {
        if (rs?.Code === 0 && rs?.Data?.length > 0) {
          const item = rs?.Data[0];
          setServiceStatus(item?.ServiceStatus);
        } else {
          MessageError(rs?.Message);
        }
      })
      .catch((err) => {
        MessageError(err);
      });
  }, []);
  const handleClick = () => {
    setVisible(true);
  };
  const onCancel = () => {
    setVisible(false);
  };
  const askHandle = () => {
    window.location.href = STUFF_URL;
  };
  const isExpired = useMemo(() => serviceStatus === ServiceStatusEnum.expired, [serviceStatus]);

  /**
   * 埋点
   * @param {string} button - 按钮名称
   */
  const report = (button: string) => {
    const openUserId = getWeworkOpenUserId();
    const tandonUserId = getWeworkTandonUserId();
    reportCustomEvent('feature-click-about-andon', `${tandonUserId}-${openUserId}-${button}`, `${dayjs().format('YYYY-MM-DD HH:mm:ss')}`);
  };

  useEffect(() => {
    reportFrontData({
      action: '页面访问',
      module: ReportModule.ABOUT_ANDON,
    });
  }, []);

  return (
    <div className={s.landingPageContainer}>
      {showLoading && <LoadingPage />}
      <div className={s.landingPageHeader}>
        <div className={s.landingPageHeaderCenter} id="landingPageHeaderCenter">
          <p className={s.landingPageHeaderDes}>
            <span className={s.landingPageHeaderTitle}>腾讯云安灯</span>
            ，ITSM服务管理平台，融合ITIL4最佳实践理论，助力企业构建IT服务管理体系。基于标准ITSM框架，覆盖从事件产生、事件处理、根因分析、问题沉淀到产品变更的全服务管理生命周期。提供可视化、可量化的服务管理能力，帮助服务团队轻松应对意外事件，提升产品可用性及服务连续性。
          </p>
          {serviceStatus !== ServiceStatusEnum.normal && (
            <Button className={s.experienceButton} onClick={handleClick}>
              立即试用
            </Button>
          )}
          <Dialog
            header={isExpired ? '当前企业试用版已到期' : ' '}
            visible={visible}
            confirmOnEnter
            onClose={onCancel}
            onCancel={onCancel}
            onEscKeydown={onCancel}
            onCloseBtnClick={onCancel}
            onOverlayClick={onCancel}
            onConfirm={askHandle}
            confirmBtn={isExpired ? '立即咨询' : null}
            cancelBtn={isExpired ? '取消' : null}
            className={s.dialog}
            attach="#landingPageHeaderCenter"
          >
            {serviceStatus === ServiceStatusEnum.nonactivated ? (
              <DialogContent setShowLoading={setShowLoading} setVisible={setVisible} visible={visible} />
            ) : (
              <div>联系企微客服开通正式版应用</div>
            )}
          </Dialog>
        </div>
      </div>
      <div className={s.landingPageProcess}>
        <div className={s.landingPageProcessCenter}>
          <span className={s.landingPageProcessTitle}>安灯不止是平台，更是一种管理方式</span>
          <span className={s.landingPageProcessSubTitle}>
            将ITIL最佳实践精髓与精益管理理念融合，为企业带来管理文化的升级
          </span>
          <Rectangle className={s.landingPageProcessRectangle} />
          <img className={s.landingPageProcessPng} src={processImg} alt="processImg" />
          <div>
            <CustomerPull className={s.customerPull} />
            <span className={s.customerPullTitle}>客户拉动，流程端到端</span>
            <span className={s.customerPullSubTitle}>
              赋予员工权力，如调整单据优先级、快速向上报备等。一张单据传递上下文，直击客户第一现场。
            </span>
          </div>
          <div>
            <BadCase className={s.badCase} />
            <span className={s.badCaseTitle}>Bad Case驱动向上升级</span>
            <span className={s.badCaseSubTitle}>
              问题无法解决时，客服同学可将客户问题通过“按灯机制”直达管理层；腾讯云安灯完善的SLA机制”，帮助管理层、决策层进行例外管理。
            </span>
          </div>
          <div>
            <Lean className={s.lean} />
            <span className={s.leanTitle}>精益管理，持续改进</span>
            <span className={s.leanSubTitle}>
              完善的复盘机制、问题管理助力企业解决流程层面的问题，从根本上预防此类问题的再次发生。沿着服务价值流的方向，不断追求完美，打造精益服务。（迭代中，敬请期待）
            </span>
          </div>
        </div>
      </div>
      <div className={s.landingPageTabs}>
        <div className={s.landingPageTabsCenter}>
          <span className={s.landingPageTabsTitle}>产品特性</span>
          <div className={s.landingPageTabsInner}>
            <div className={s.wrapper}>
              <Link onClick={() => report('事件单协同管理')} to="/workbench/handler">
                <div className={s.feature}>
                  <div className={s.featureLeft}>
                    <img src={feat001} alt="" />
                  </div>
                  <div className={s.featureRight}>

                    <div className={s.featureTitle}>
                      事件单协同管理
                      <span className={`${s.featureLink} link`}>去建单&gt;</span>
                    </div>
                    <div className={s.featureContent}>以ITIL为指导原则，输出腾讯云事件管理实践：每个事件均可协同处理，可视化所有排查过程信息，提升您的企业事件处理效率；</div>
                  </div>
                </div>
              </Link>
              <Link onClick={() => report('实时报表管理')} to="/overview">
                <div className={s.feature}>
                  <div className={s.featureLeft}>
                    <img src={feat002} alt="" />
                  </div>
                  <div className={s.featureRight}>
                    <div className={s.featureTitle}>
                      实时报表管理
                      <span className={`${s.featureLink} link`}>去看看&gt;</span>
                    </div>
                    <div className={s.featureContent}>实时的报表数据，动态掌握组织工作情况，给您开箱即用的报表体验；</div>
                  </div>
                </div>
              </Link>
              <Link onClick={() => report('按灯管理')} to="/configcenter/platform/queue?active=2">
                <div className={s.feature}>
                  <div className={s.featureLeft}>
                    <img src={feat003} alt="" />
                  </div>
                  <div className={s.featureRight}>
                    <div className={s.featureTitle}>
                      “按灯”管理
                      <span className={`${s.featureLink} link`}>去配置&gt;</span>
                    </div>
                    <div className={s.featureContent}>利用OLA对“响应时效”、“处理时效”进行管理；联动报表智能化推荐配置数据。告警按组织架构梯度配置，让异常情况层层升级；</div>
                  </div>
                </div>
              </Link>
            </div>
            <div className={s.wrapper}>
              <Link onClick={() => report('以客户为中心')} to="/workbench/handler">
                <div className={s.feature}>
                  <div className={s.featureLeft}>
                    <img src={feat004} alt="" />
                  </div>
                  <div className={s.featureRight}>
                    <div className={s.featureTitle}>
                      以客户为中心
                      <span className={`${s.featureLink} link`}>去建单&gt;</span>
                    </div>
                    <div className={s.featureContent}>单据创建人有权利结单并评价。内置五档满意度评价，让服务质量可量化；</div>
                  </div>
                </div>
              </Link>
              <Link onClick={() => report('丰富的连接场景')} to="/configcenter/platform/link">
                <div className={s.feature}>
                  <div className={s.featureLeft}>
                    <img src={feat005} alt="" />
                  </div>
                  <div className={s.featureRight}>
                    <div className={s.featureTitle}>
                      丰富的连接场景
                      <span className={`${s.featureLink} link`}>去连接&gt;</span>
                    </div>
                    <div className={s.featureContent}>支持多种渠道：直连微信客服（外部客户1v1场景）、员工助手（内部业务1v1场景）、 客户群（外部客户1vN场景）；</div>
                  </div>
                </div>
              </Link>
              <Link onClick={() => report('人员管理')} to="/configcenter/platform/queue">
                <div className={s.feature}>
                  <div className={s.featureLeft}>
                    <img src={feat006} alt="" />
                  </div>
                  <div className={s.featureRight}>
                    <div className={s.featureTitle}>
                      人员管理
                      <span className={`${s.featureLink} link`}>去管理&gt;</span>
                    </div>
                    <div className={s.featureContent}>以队列为组织单元，队列处理人彼此互相备份。支持设置接单规则，进行智能化单据流转、派单；</div>
                  </div>
                </div>
              </Link>
            </div>
            <div className={s.wrapper}>
              <Link onClick={() => report('便捷的账号体系')} to="/configcenter/platform/queue?active=5">
                <div className={s.feature}>
                  <div className={s.featureLeft}>
                    <img src={feat007} alt="" />
                  </div>
                  <div className={s.featureRight}>
                    <div className={s.featureTitle}>
                      便捷的账号体系
                      <span className={`${s.featureLink} link`}>去配置&gt;</span>
                    </div>
                    <div className={s.featureContent}>与企业微信账号打通，无需多账号切换。集成单点登录，企业内部成员天然联动；</div>
                  </div>
                </div>
              </Link>
              <Link onClick={() => report('简易的配置流程')} to="/configcenter/platform/servermenu">
                <div className={s.feature}>
                  <div className={s.featureLeft}>
                    <img src={feat008} alt="" />
                  </div>
                  <div className={s.featureRight}>
                    <div className={s.featureTitle}>
                      简易的配置流程
                      <span className={`${s.featureLink} link`}>去配置&gt;</span>
                    </div>
                    <div className={s.featureContent}>轻松两步完成系统配置: 1、定义组织；2、定义业务。</div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={s.landingPageCase}>
        <div className={s.landingPageCaseImg} />
        <div className={s.landingPageCaseScroll}>
          <span className={s.landingPageCaseScrollTitle}>他们也在用腾讯云安灯</span>
          <span className={s.landingPageCaseScrollDes}>腾讯云安灯与卓越品牌携手打造精益管理的优秀实践</span>
          <div className={s.landingPageCaseScrollContent}>
            <div className={s.landingPageCaseScrollCards}>
              <CaseScroll />
            </div>
          </div>
        </div>
      </div>
      <div className={s.landingPageCaseMini}>
        <div className={s.tencentCloudMiniCard}>
          <img src={tencentCloudMiniLogo} alt="tencentCloudMiniLogo" className={s.tencentCloudMiniLogo} />
        </div>
        <div className={s.workWxMiniCard}>
          <img src={workWxMiniLogo} alt="workWxMiniLogo" className={s.workWxMiniLogo} />
        </div>
        <div className={s.tencentConferenceMiniCard}>
          <img
            src={tencentConferenceMiniLogo}
            alt="tencentConferenceMiniLogo"
            className={s.tencentConferenceMiniLogo}
          />
        </div>
        <div className={s.tapdMiniCard}>
          <img src={tapdMiniLogo} alt="tapdMiniLogo" className={s.tapdMiniLogo} />
        </div>
        <div className={s.wxPayMiniCard}>
          <img src={wxPayMiniLogo} alt="wxPayMiniLogo" className={s.wxPayMiniLogo} />
        </div>
      </div>
    </div>
  );
}
