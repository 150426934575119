import React from 'react';
import { NoAuth } from '@shared/tandon-design/no-auth';
import s from './index.module.scss';

export default function ExpiredPage(): React.ReactElement {
  return (
    <div className={s.expiredPageContainer}>
      <NoAuth describe="您的套餐已到期" eventDescribe="立即续费" />
    </div>
  );
}
