import { MessageError } from '@shared/tandon-design/message-error';
import { post, ResponseResult } from '@shared/tandon-plugin/request';

/**
 * 用户是否查看过某种一次性信息？
 */
export const getViewFlag = async (key: string) => (post('/api/v1/incident/GetViewFlag', { ViewKey: key }) as Promise<ResponseResult<{ ViewFlag: number }>>)
  .then(({ Code, Message, Data }) => {
    if (Code !== 0) throw new Error(Message);
    return !!Data.ViewFlag;
  })
  .catch((e: any) => {
    MessageError(e.message);
    return true;
  });

/**
 * 标记用户查看过某种一次性信息
 */
export const saveViewFlag = async (key: string, value = 1) => (post('/api/v1/incident/SaveViewFlag', { ViewKey: key, ViewFlag: value }) as Promise<ResponseResult<{}>>)
  .then(({ Code, Message }) => {
    if (Code !== 0) throw new Error(Message);
    return true;
  })
  .catch((e: any) => {
    MessageError(e.message);
    return false;
  });
