/* eslint-disable no-bitwise */
/* eslint-disable no-multi-spaces */

/** --------------------------------模板字段------------------------------------------------ */
/** 字段类型 */
export enum FieldType {
  Input = 'input',             // 单行文本
  Textarea = 'textarea',       // 多行文本
  Radio = 'radio',             // 单选
  Checkbox = 'checkbox',       // 多选
  Select = 'select',           // 下拉
  DatePicker = 'datepicker',   // 日期
  InputNumber = 'inputnumber', // 数值输入
}

export type TemplateField = {
  id: string;                  // short uuid
  label: string;               // 字段名称
  type: FieldType;             // 字段类型
  props: {
    placeholder?: string;
    options?: Array<{ id: string; label: string; value: string | number }>;
    sensitivity?: {
      enable: boolean;
      start?: number;
      end?: number;
    };
  };

  isSystem?: 1;
};

export type ComputedTemplateField = TemplateField & { required: boolean };

export interface FieldProps {
  field: TemplateField
}

/** --------------------------------模板------------------------------------------------ */

/** 模板By服务目录 */
export type Template = Array<{
  fieldId: string;              // 字段id
  applyRules: number;           // 应用场景 6个bit
}>;

export const CREATE_OPTIONAL = 1 << 5;         // 提单选填
export const CREATE_REQUIRED = 1 << 4;         // 提单必填
export const TRANSFER_OPTIONAL = 1 << 3;       // 转单选填
export const TRANSFER_REQUIRED = 1 << 2;       // 转单必填
export const APPLY_CLOSE_OPTIONAL = 1 << 1;    // 申请结单选填
export const APPLY_CLOSE_REQUIRED = 1;         // 申请结单必填

/** 模板字典，用服务目录id检索 */
export type TemplateDictionary = {
  [categoryId: number]: Template;
};

export const ROOT_ID = 0;

export type TemplateConfig = { templates: TemplateDictionary };

export enum TemplateType {
  Create = 'create', // 提单
  ApplyClose = 'applyClose', // 申请结单
}
