import { MicroAppEnum, MICRO_APP_CONTAINER } from '@/constants/micro-app-config';
import { S_KEY } from '@/components/micro-dev-mode';
import { IMicroDevConfig } from '@/components/micro-dev-mode/index.type';

const isEnvDevelopment = process.env.NODE_ENV === 'development';

const { REACT_APP_TANDON_DOMAIN, REACT_APP_CDN_URL, REACT_APP_RUNTIME_ENV } = process.env;

const isProd = REACT_APP_RUNTIME_ENV === 'prod';

const defaultMicroAppConfig = [
  {
    name: MicroAppEnum.overview, // 总览
    entry: isEnvDevelopment ? '//localhost:3001' : `${REACT_APP_CDN_URL}andon-fe/overview/index.html`,
    container: MICRO_APP_CONTAINER,
    activeRule: '/pc/overview',
    props: {},
  },
  {
    name: MicroAppEnum.workbench, // 我的工作台
    entry: isEnvDevelopment ? '//localhost:3002' : `${REACT_APP_CDN_URL}andon-fe/workbench/index.html`,
    container: MICRO_APP_CONTAINER,
    activeRule: '/pc/workbench',
    props: {},
  },
  {
    name: MicroAppEnum.configcenter, // 配置中心
    entry: isEnvDevelopment ? '//localhost:3003' : `${REACT_APP_CDN_URL}andon-fe/config-center/index.html`,
    container: MICRO_APP_CONTAINER,
    activeRule: '/pc/configcenter',
    props: {},
  },
  {
    name: MicroAppEnum.servermanager, // IT服务管理
    entry: isEnvDevelopment ? '//localhost:3004' : `${REACT_APP_CDN_URL}andon-fe/server-manager/index.html`,
    container: MICRO_APP_CONTAINER,
    activeRule: '/pc/servermanager',
    props: {},
  },
  {
    name: MicroAppEnum.knowledge, // 知识库
    entry: isEnvDevelopment ? '//localhost:3005' : `${REACT_APP_CDN_URL}andon-fe/knowledge/index.html`,
    container: MICRO_APP_CONTAINER,
    activeRule: '/pc/knowledge',
    props: {},
  },
  {
    name: 'tbpm', // tbpm
    entry: `//${REACT_APP_TANDON_DOMAIN}/micro/tbpm`,
    container: MICRO_APP_CONTAINER,
    activeRule: '/pc/tbpm',
    props: {},
  },
];

const getFinalMicroAppConfig = () => {
  const storage = window.localStorage;
  try {
    const itemStr = storage.getItem(S_KEY) ?? '';
    let config: IMicroDevConfig | undefined;
    if (itemStr) {
      config = JSON.parse(itemStr);
    }
    if (typeof config === 'object') {
      return defaultMicroAppConfig.map((e) => {
        const appName = e.name ?? '';
        const devConfig = config?.[appName] ?? {};
        if (devConfig.devSwitch) {
          return {
            ...e,
            entry: devConfig.url ?? e.entry,
          };
        }
        return e;
      });
    }
    return defaultMicroAppConfig;
  } catch (e: any) {
    console.error(e?.message);
    return defaultMicroAppConfig;
  }
};

// 生产环境屏蔽microDev模式
const FINAL_MICRO_APP_CONFIG = isProd ? defaultMicroAppConfig : getFinalMicroAppConfig();

export default FINAL_MICRO_APP_CONFIG;
