/**
 * @description icon svg
 * @author wangfupeng
 */

/**
 * 【注意】svg 字符串的长度 ，否则会导致代码体积过大
 * 尽量选择 https://www.iconfont.cn/collections/detail?spm=a313x.7781069.0.da5a778a4&cid=20293
 * 找不到再从 iconfont.com 搜索
 */

// 附件
// export const ATTACHMENT_SVG = '<svg viewBox="0 0 1024 1024"><path d="M665.821116 327.053068l-64.958782-64.926783-324.79391 324.729911c-53.822991 53.790991-53.822991 141.053355 0 194.844347s141.053355 53.790991 194.876346 0l389.752692-389.688693a229.531696 229.531696 0 0 0 0-324.729912 229.627694 229.627694 0 0 0-324.761911 0L126.727224 476.458266l-0.863984 0.831985c-125.085655 125.085655-125.085655 327.833853 0 452.887508 125.053655 125.053655 327.865853 125.053655 452.983507 0l0.831984-0.863984 0.031999 0.032 279.322763-279.290764-64.990781-64.926782-279.322763 279.258764-0.831984 0.831984a228.731711 228.731711 0 0 1-323.065943 0 228.603714 228.603714 0 0 1 0-322.969944l0.895983-0.831985-0.031999-0.063998L600.958332 132.208721c53.694993-53.726993 141.149353-53.726993 194.876346 0s53.694993 141.149353 0 194.844347L406.081986 716.741761c-17.919664 17.919664-47.039118 17.919664-64.958782 0a45.983138 45.983138 0 0 1 0-64.926783L665.917114 327.053068z"></path></svg>';
export const ATTACHMENT_SVG = '<svg t="1689751277522" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6063" width="32" height="32"><path d="M576 256l-128-128H0v832h1024V256H576z m-64 224l224 224h-160v256h-128v-256H288l224-224z" p-id="6064"></path></svg>';
