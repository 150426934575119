import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

let jwt: any = {};

interface IPayloadItem {
  OpenUserId: string;
  CorpId: string;
  UserId: string;
  TandonUserId: string;
  TenantId: string;
  TenantName: string;
}

try {
  jwt = jwtDecode(Cookies.get('tandon_jwt') ?? '');
} catch (e) {
  console.error(e);
}

const { OpenUserId, CorpId, UserId, TandonUserId, TenantId, TenantName } = (jwt?.Payload as IPayloadItem) ?? {};

// 获取企微认证应用的corpid
export const getWeworkCorpid = (): string => CorpId;
export const getWeworkOpenUserId = (): string => OpenUserId;
export const getWeworkUserId = (): string => UserId;
export const getWeworkTandonUserId = (): string => TandonUserId;
export const getWeworkTenantName = (): string => TenantName;
export const getWeworkTenantId = (): string => TenantId;
export const getWeworkTokenObj = (): IPayloadItem => jwt?.Payload ?? {};
export const getWeworkJWTToken = (): string => Cookies.get('tandon_jwt');
// 判断是否登录，先阶段就根据cookie里面是否存在tandon_jwt字段来进行判断，后面逻辑可能会有变化
export const getLoginStatus = (): boolean => !!Cookies.get('tandon_jwt');
