import React, { useState } from 'react';
import tencentCloudLogo from '@/assets/png/tencent-cloud-logo.png';
import wxPayLogo from '@/assets/png/wx-pay-logo.png';
import tencentConferenceLogo from '@/assets/png/tencent-conference-logo.png';
import caseLogo from '@/assets/png/case-icon.png';
import { ReactComponent as LeftScroll } from '@/assets/svg/left-scroll.svg';
import { ReactComponent as RightScroll } from '@/assets/svg/right-scroll.svg';
import s from './index.module.scss';

export default function CaseScroll(): React.ReactElement {
  const [data] = useState<any>([
    {
      id: 1,
      logo: tencentCloudLogo,
      title: '腾讯云',
      subTitle: '“用安灯拉通1w+工程师进行高效协作。”',
      des: '引入精益生产理念与ITIL七大原则，通过端到端的服务流程，保障客户满意，提升大服务体系的协同效率，用数据驱动运营质量和效率提升。',
      logoWidth: 40,
      logoHeight: 29,
    },
    {
      id: 2,
      logo: wxPayLogo,
      title: '微信支付',
      subTitle: '“商户满意度从半年度调研到日常可衡量。”',
      des: '使用腾讯云安灯为各类企业以及小微商户提供专业的收款能力，运营能力，资金结算解决方案，以及安全保障。',
      logoWidth: 40,
      logoHeight: 36,
    },
    {
      id: 3,
      logo: tencentConferenceLogo,
      title: '腾讯会议',
      subTitle: '“用腾讯云安灯覆盖企业内外的全量客户。”',
      des: '通过腾讯云安灯提供的WebIM+ITSM流程平台+可视化平台，腾讯会议在内外部咨询量暴涨的背景下，依旧保持了高客户满意度。',
      logoWidth: 48,
      logoHeight: 27,
    },
    {
      id: 4,
      logo: tencentCloudLogo,
      title: '腾讯云',
      subTitle: '“用安灯拉通1w+工程师进行高效协作。”',
      des: '引入精益生产理念与ITIL七大原则，通过端到端的服务流程，保障客户满意，提升大服务体系的协同效率，用数据驱动运营质量和效率提升。',
      logoWidth: 40,
      logoHeight: 29,
    },
    {
      id: 5,
      logo: wxPayLogo,
      title: '微信支付',
      subTitle: '“商户满意度从半年度调研到日常可衡量。”',
      des: '使用腾讯云安灯为各类企业以及小微商户提供专业的收款能力，运营能力，资金结算解决方案，以及安全保障。',
      logoWidth: 40,
      logoHeight: 36,
    },
    {
      id: 6,
      logo: tencentConferenceLogo,
      title: '腾讯会议',
      subTitle: '“用腾讯云安灯覆盖企业内外的全量客户。”',
      des: '通过腾讯云安灯提供的WebIM+ITSM流程平台+可视化平台，腾讯会议在内外部咨询量暴涨的背景下，依旧保持了高客户满意度。',
      logoWidth: 48,
      logoHeight: 27,
    },
    {
      id: 7,
      logo: tencentCloudLogo,
      title: '腾讯云',
      subTitle: '“用安灯拉通1w+工程师进行高效协作。”',
      des: '引入精益生产理念与ITIL七大原则，通过端到端的服务流程，保障客户满意，提升大服务体系的协同效率，用数据驱动运营质量和效率提升。',
      logoWidth: 40,
      logoHeight: 29,
    },
    {
      id: 8,
      logo: wxPayLogo,
      title: '微信支付',
      subTitle: '“商户满意度从半年度调研到日常可衡量。”',
      des: '使用腾讯云安灯为各类企业以及小微商户提供专业的收款能力，运营能力，资金结算解决方案，以及安全保障。',
      logoWidth: 40,
      logoHeight: 36,
    },
    {
      id: 9,
      logo: tencentConferenceLogo,
      title: '腾讯会议',
      subTitle: '“用腾讯云安灯覆盖企业内外的全量客户。”',
      des: '通过腾讯云安灯提供的WebIM+ITSM流程平台+可视化平台，腾讯会议在内外部咨询量暴涨的背景下，依旧保持了高客户满意度。',
      logoWidth: 48,
      logoHeight: 27,
    },
  ]);

  const [fisrtIndex, setFisrtIndex] = useState(3);

  const addFisrtIndexHandle = () => {
    if (fisrtIndex >= 5) {
      setFisrtIndex(0);
      return;
    }
    setFisrtIndex((prev: number) => prev + 1);
  };

  const reduceFisrtIndexHandle = () => {
    if (fisrtIndex <= 1) {
      setFisrtIndex(6);
      return;
    }
    setFisrtIndex((prev: number) => prev - 1);
  };

  return (
    <div className={s.caseScrollInner}>
      { fisrtIndex }
      {
        data.slice(fisrtIndex, fisrtIndex + 3).map((item: any) => (
          <div className={s.landingPageCaseItem} key={item.id}>
            <img className={s.caseLogo} src={caseLogo} alt="caseLogo" />
            <img className={s.landingPageCaseItemLogo} src={item.logo} alt={item.title} height={item.logoHeight} width={item.logoWidth} />
            <span className={s.landingPageCaseItemTitle}>{item.title}</span>
            <span className={s.landingPageCaseItemSubTitle}>{item.subTitle}</span>
            <span className={s.landingPageCaseItemDes}>{item.des}</span>
          </div>
        ))
      }
      <LeftScroll className={s.leftScroll} onClick={reduceFisrtIndexHandle} />
      <RightScroll className={s.rightScroll} onClick={addFisrtIndexHandle} />
    </div>
  );
}
