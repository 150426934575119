import { IDomEditor, IEditorConfig } from '@wangeditor/editor';
import { AttachmentElement } from '../custom-types';
import { IUploadConfigForAttachment } from './config';

export interface ICustomEditorConfig extends IEditorConfig {
  CUSTOM_CONF: {
    uploadAttachment: IUploadConfigForAttachment
  }
}

function getUploadAttachmentMenuConfig(editor: IDomEditor): IUploadConfigForAttachment {
  const config = editor.getConfig() as ICustomEditorConfig;
  return config.CUSTOM_CONF.uploadAttachment;
}

/**
 * 插入 attachment 节点
 * @param fileName fileName
 * @param link link
 */
export function insertAttachment(editor: IDomEditor, fileName: string, link: string) {
  if (!fileName || !link) return;

  // 还原选区
  editor.restoreSelection();

  // 插入节点
  const attachmentElem: AttachmentElement = {
    type: 'attachment',
    fileName,
    link,
    children: [{ text: '' }],
  };
  editor.insertNode(attachmentElem);
  if (editor.getHtml().endsWith('</span>')) {
    const p = { type: 'paragraph', children: [{ text: '' }] };
    editor.insertNode(p);
  }
  editor.moveReverse(1);
}

/**
 * 上传附件文件
 * @param editor editor
 * @param files files
 */
export async function uploadAttachments(editor: IDomEditor, files: FileList | null) {
  if (files === null) return;
  const fileList = Array.prototype.slice.call(files);

  // 获取菜单配置
  const { customUpload } = getUploadAttachmentMenuConfig(editor);

  // 按顺序上传
  for await (const file of fileList) {
    await customUpload?.(file, (url: string, fileName: string, _link: string) => insertAttachment(editor, fileName, url));
  }
}
