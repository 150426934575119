import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { reportCommonEvent } from '@shared/tandon-plugin/aegis';
import { generateRandomString } from '../utils';

export function reportRequestResolve(config: AxiosRequestConfig): AxiosRequestConfig {
  const traceId = generateRandomString(32);
  // @ts-ignore
  // eslint-disable-next-line no-param-reassign
  config.headers['Trace-Id'] = traceId;
  // @ts-ignore
  // eslint-disable-next-line no-param-reassign
  config.headers.Traceparent = `00-${traceId}-${generateRandomString(16)}-01`;
  reportCommonEvent({
    event: 'TC ANDON Request',
    data: config,
  });
  return {
    ...config,
  };
}

export function reportResponseResolve(response: AxiosResponse): AxiosResponse {
  reportCommonEvent({
    event: 'TC ANDON Response',
    data: response,
  });
  return response;
}
