/**
 * @description src entry
 * @author wangfupeng
 */

import module from './module/index';

export * from './module/custom-types';

export default module;
