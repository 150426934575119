import React from 'react';

import noauth from '../assets/pngs/no-auth.png';
import s from './index.module.scss';

interface NoAuthPropsType {
  describe?: string;
  eventDescribe?: string;
}
export default function NoAuthComponent(props: NoAuthPropsType): React.ReactElement {
  const { describe, eventDescribe } = props;

  const askHandle = () => {
    window.location.href = 'wxwork://message?uin=1688854804393209';
  };

  return (
    <div className={s.content}>
      <div className={s.img}>
        <img src={noauth} alt="noauth" />
      </div>
      {describe && eventDescribe ? (
        <div className={s.text}>
          {describe}
          <span className={s.button} onClick={askHandle}>
            {eventDescribe}
          </span>
        </div>
      ) : (
        <div className={s.text}>
          您暂时没有访问该页面的权限，联系管理员开通
        </div>
      )}
    </div>
  );
}

export const NoAuth = NoAuthComponent;
