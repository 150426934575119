/* eslint-disable @typescript-eslint/brace-style */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { storage } from '@shared/tandon-plugin/storage';
import { BrowserRouter } from 'react-router-dom';
import { getLoginStatus } from '@shared/tandon-plugin/token';
import { TANDON_REFRESH_TOKEN, redirectToNoAuthPage, rewriteXhr } from '@shared/tandon-plugin/request';
import { SingletonAegis, TcAndonAegisIdMap } from '@shared/tandon-plugin/aegis';
import { delUrlParam, getQueryVariable, isRedirectMobile } from '@/utils/index';
import './index.css';
import App from './App';

if (isRedirectMobile) {
  const searchParams = new URLSearchParams(window.location.search);
  const name = searchParams.get('name') || searchParams.get('Title');
  const id = searchParams.get('id') || searchParams.get('IncidentID');
  if (name && id) {
    window.location.href = `/chat-toolbar/wechat-cs-workbench/detail?id=${id}&name=${name}`;
  } else {
    window.location.href = '/chat-toolbar/wechat-cs-workbench';
  }
}

else if (!getLoginStatus()) redirectToNoAuthPage();

else {
  const { REACT_APP_RUNTIME_ENV } = process.env;
  // @ts-ignore
  SingletonAegis.setAegisId(TcAndonAegisIdMap.tandonPc[REACT_APP_RUNTIME_ENV]);

  rewriteXhr();

  // 获取后端重定向后，加到url上的refresh_token参数，并将其set到local中存储
  const refreshToken = getQueryVariable('refresh_token');
  if (refreshToken) {
    storage.local.set(TANDON_REFRESH_TOKEN, refreshToken);
    // 去掉url参数
    let url = delUrlParam(['refresh_token', 'first_login']);
    const useSearch = new URLSearchParams(window.location.search);
    const firstLogin = useSearch.get('first_login');
    if (firstLogin === 'true') {
      url = `${window.location.protocol}//${window.location.host}/pc/about-andon`;
      window.location.href = url;
    } else {
      // 替换url不刷新页面
      window.history.pushState('', '', url);
    }
  }

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render((
    <React.StrictMode>
      <BrowserRouter basename="/pc">
        <App />
      </BrowserRouter>
    </React.StrictMode>
  ));
}
