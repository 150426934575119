/* eslint-disable no-bitwise */
import { TemplateField, FieldType, Template, CREATE_REQUIRED, TRANSFER_REQUIRED } from './type';

export enum SystemFieldId {
  Priority = 'Priority',
}

/**
 * 兜底的优先级字段
 */
const DEFAULT_SYSTEM_PRIORITY: TemplateField = {
  isSystem: 1,

  id: SystemFieldId.Priority,
  label: '优先级',
  type: FieldType.Radio,

  props: {
    options: [
      { id: 'High', value: 3, label: '高' },
      { id: 'Middle', value: 2, label: '中' },
      { id: 'Low', value: 1, label: '低' },
    ],
  },
};

export const DEFAULT_SYSTEM_FIELDS = [
  DEFAULT_SYSTEM_PRIORITY,
];

export const SYSTEM_TEMPLATE: Template = [
  { fieldId: SystemFieldId.Priority, applyRules: CREATE_REQUIRED | TRANSFER_REQUIRED },
];
