/* eslint-disable complexity */
import { describeSvcDirWarn } from '@/service/tenant';
import { getTxcUserData } from '@/service/user';
import { STUFF_URL } from '@/constants';
import React, { useState, useLayoutEffect, useEffect, useContext } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import {
  UserTimeIcon,
  RootListIcon,
  ControlPlatformIcon,
  FileIcon,
  HomeIcon,
  SettingIcon,
  ViewListIcon,
  UsergroupAddIcon,
  LinkIcon,
  UserLockedIcon,
  ErrorCircleFilledIcon,
  TaskIcon,
  TaskCheckedIcon,
  TaskMarkedIcon,
  TemplateIcon,
  MenuUnfoldIcon,
} from 'tdesign-icons-react';
import { Menu, Divider, Button } from 'tdesign-react';
import { ReportModule, reportFrontData } from '@shared/tandon-plugin/report';
import { storage } from '@shared/tandon-plugin/storage';
import { describeAppAuthorizations } from '@shared/tandon-design/workbench/api';
import { MessageError } from '@shared/tandon-design/message-error';
import { APP_AUTH_STATUS, RELATION_APP_TYPE } from '@shared/tandon-design/workbench/constants';
import { PermissionConfig } from '@shared/tandon-plugin/permission';
import contactIcon from '@/assets/svg/contact.svg';
import tcloudIcon from '@/assets/png/tencent-cloud-logo.png';
import tcloudDefaultIcon from '@/assets/png/tencent-cloud-default-logo.png';
import Tucao from '@/utils/tucao';
import Context from '@/context';
import s from './index.module.scss';

const { SubMenu, MenuItem } = Menu;

interface LeftMenuProps {
  updateMenuStatus: boolean;
  queueStatus: boolean;
}

export default function LeftMenu(props: LeftMenuProps): React.ReactElement {
  const [collapsed, setCollapsed] = useState(false);

  const { updateMenuStatus, queueStatus } = props;
  const [active, setActive] = useState<any>(undefined);
  const [serverMenuStatus, setServerMenuStatus] = useState(false);
  const [userData, setUserData] = useState('');
  const [isShowKnowledge, setIsShowKnowledge] = useState(false);
  const [show360, setShow360] = useState(false);
  const { user } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    setMenuActive();
  }, [location.pathname]);

  useEffect(() => {
    describeSvcDirWarn().then((d) => {
      setServerMenuStatus(d.Data);
    });
  }, [updateMenuStatus]);

  useEffect(() => {
    describeAppAuthorizations({
      AppType: RELATION_APP_TYPE.SERVICE360,
    }).then((res) => {
      if (res.Code === 0) {
        const appAuthorizations = res.Data.AppAuthorizations[0] || {};
        const isAuthorized = appAuthorizations.Status === APP_AUTH_STATUS.AUTHORIZED;
        setShow360(isAuthorized);
      }
    });
  }, []);

  const setMenuActive = () => {
    const { pathname } = location;
    if (pathname?.includes('overview')) {
      setActive('overview');
    } else if (pathname?.includes('workbench')) {
      setActive('workbench');
    } else if (pathname?.includes('servermanager')) {
      setActive(pathname?.includes('service360') ? 'service360' : 'servermanager');
    } else if (pathname?.includes('schedule')) {
      setActive('schedule');
    } else if (pathname?.includes('server')) {
      setActive('server');
    } else if (pathname?.includes('queue')) {
      setActive('queue');
    } else if (pathname?.includes('about-andon')) {
      setActive('about-andon');
    } else if (pathname?.includes('link')) {
      setActive('link');
    } else if (pathname?.includes('license')) {
      setActive('license');
    } else if (pathname?.includes('permission')) {
      setActive('permission');
    } else if (pathname?.includes('unorganized')) {
      setActive('unorganized');
    } else if (pathname?.includes('organized')) {
      setActive('organized');
    // } else if (pathname?.includes('tag-manager')) {
    //   setActive('tag-manager');
    } else if (pathname?.includes('template')) {
      setActive('template');
    }
  };

  const onClickServermanager = () => {
    const tabsInfo = storage.session.get('ServerManagerTabs') || {};
    tabsInfo.activeTab = 'list';
    tabsInfo.showType = 'list';
    storage.session.set('ServerManagerTabs', tabsInfo);
    navigate('/servermanager/manager/things');
  };

  useEffect(() => {
    initTxcUrl();
  }, []);

  const initTxcUrl = async () => {
    const { Code, UserData } = await getTxcUserData();
    if (Code === 0) {
      setUserData(UserData);
    }
  };

  const onContact = () => {
    reportFrontData({
      action: '页面访问',
      module: ReportModule.PRODUCT_VERSION,
    });
    window.location.href = STUFF_URL;
  };

  const onVersion = () => {
    reportFrontData({
      action: '页面访问',
      module: ReportModule.CONTACT_US,
    });
    const productId = 597557;
    if (/MicroMessenger/i.test(navigator.userAgent)) {
      window.open(`https://support.qq.com/products/${productId}/roadmap?user_data=${userData}`, '_blank');
    } else {
      Tucao.request(productId, {
        user_data: userData,
      });
    }
  };

  useEffect(() => {
    describeAppAuthorizations({
      AppType: RELATION_APP_TYPE.KNOWLEDGE,
    }).then((res) => {
      if (res.Code === 0) {
        const isShowKnowledge = res.Data.AppAuthorizations?.[0]?.Status === 1;
        setIsShowKnowledge(isShowKnowledge);
      } else {
        MessageError(res.Message);
      }
    });
  }, []);

  return (
    <div className={s.menus}>
      <Menu
        style={{ maxHeight: 'calc(100% - 90px)' }}
        collapsed={collapsed}
        value={active}
        onChange={(v: any) => {
          setActive(v);
        }}
        defaultExpanded={['workbench', 'configcenter', 'knowledge']}
      >
        {show360 && (
          <MenuItem
            className={s.service360}
            value="service360"
            onClick={() => navigate('/servermanager/service360')}
            icon={<img width="20" src={active === 'service360' ? tcloudIcon : tcloudDefaultIcon} alt="" />}
          >
            服务360
          </MenuItem>
        )}
        <MenuItem
          value="workbench"
          onClick={() => navigate('/workbench/handler')}
          icon={<HomeIcon />}
        >
          我的
        </MenuItem>
        {
          user?.permissions.includes(PermissionConfig.INCIDENT_ALL_VIEW) && (
            <MenuItem
              value="servermanager"
              onClick={onClickServermanager}
              icon={<RootListIcon />}
            >
              事件
            </MenuItem>
          )
        }
        <MenuItem value="overview" onClick={() => navigate('/overview')} icon={<FileIcon />}>
          报表
        </MenuItem>
        {
          isShowKnowledge ? (
            <SubMenu value="knowledge" title="知识库" icon={<TaskIcon />}>
              <MenuItem
                value="unorganized"
                onClick={() => navigate('/knowledge/unorganized/list')}
                icon={<TaskMarkedIcon />}
              >
                AI 生成
              </MenuItem>
              <MenuItem
                value="organized"
                onClick={() => navigate('/knowledge/organized/list')}
                icon={<TaskCheckedIcon />}
              >
                人工整理
              </MenuItem>
            </SubMenu>
          ) : (
            <MenuItem
              value="organized"
              onClick={() => navigate('/knowledge/organized/list')}
              icon={<TaskIcon />}
            >
              知识库
            </MenuItem>
          )
        }
        <SubMenu value="configcenter" title="配置" icon={<SettingIcon />}>
          <MenuItem value="schedule" onClick={() => navigate('/configcenter/platform/schedule')} icon={<UserTimeIcon />}>
            工作时间
          </MenuItem>
          <MenuItem value="queue" onClick={() => navigate('/configcenter/platform/queue')} icon={<UsergroupAddIcon />}>
            队列管理
            {queueStatus && !collapsed && (
              <>
                <ErrorCircleFilledIcon className={s.errorIcon} size="14px" />
                <div className={s.bg}>!</div>
              </>
            )}
          </MenuItem>
          <MenuItem
            value="server"
            onClick={() => navigate('/configcenter/platform/servermenu')}
            icon={<ViewListIcon />}
          >
            服务目录
            {serverMenuStatus && !collapsed && (
              <>
                <ErrorCircleFilledIcon className={s.errorIcon} size="14px" />
                <div className={s.bg}>!</div>
              </>
            )}
          </MenuItem>
          <MenuItem value="template" onClick={() => navigate('/configcenter/platform/template')} icon={<TemplateIcon />}>
            模板配置
          </MenuItem>
          {/* <MenuItem value="tag-manager" onClick={() => navigate('/configcenter/platform/tag-manager')} icon={<BookmarkIcon />}>
            <NavLink to="/tag-manager">标签管理</NavLink>
          </MenuItem> */}
          <MenuItem value="link" onClick={() => navigate('/configcenter/platform/link')} icon={<LinkIcon />}>
            <NavLink to="/link">安灯+</NavLink>
          </MenuItem>
          <MenuItem value="permission" onClick={() => navigate('/configcenter/platform/permission')} icon={<UserLockedIcon />}>
            <NavLink to="/permission">权限管理</NavLink>
          </MenuItem>
          <Divider style={{ margin: 0 }} />
        </SubMenu>
        <MenuItem value="about-andon" onClick={() => navigate('/about-andon')} icon={<ControlPlatformIcon />}>
          关于安灯
        </MenuItem> 
      </Menu>

      {collapsed ? null : (
        <div className={s.footer}>
          <div className={s.productUpdates} onClick={onVersion}>
            <div className={s.iconBox}>
              <i className={s.icon}></i>
            </div>
            <div className={s.text}>产品动态</div>
          </div>
          <Divider style={{ margin: '10px 0 0 0' }} />
          <div className={s.contactButton} onClick={onContact}>
            <img src={contactIcon} alt="联系安灯" className={s.icon} />
            <span className={s.text}>联系安灯</span>
          </div>
        </div>
      )}

      <Button
        className={s.collapseSwitch}
        variant="text"
        shape="square"
        // @ts-ignore
        icon={<MenuUnfoldIcon style={{ '--rotate': `${collapsed ? 180 : 0}deg` }} />}
        onClick={() => setCollapsed(c => !c)}
      />
    </div>
  );
}
