import axios from 'axios';
import { reportRequestResolve, reportResponseResolve } from './interceptors/aegis';
import { refreshToken } from './utils';

axios.interceptors.request.use(reportRequestResolve, error => Promise.reject(error));

axios.interceptors.response.use(
  reportResponseResolve,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        // 406小token过期进行续期操作
        case 406: {
          return refreshToken(axios, error.response);
        }
        case 401: {
          return Promise.reject(new Error('登录已过期，请重新登陆'));
        }
        default: {
          return Promise.reject(new Error('请求失败'));
        }
      }
    } else if (error.request || error.message) {
      return Promise.reject(new Error(`${error.message}`));
    }
    return Promise.reject(new Error(error.config));
  },
);

export * from './utils';
export * from './request';
export * from './interfaces';
export * from './interceptors/auth';
