export interface CacheOption {
  enable: boolean;
  expires?: number;
}

export interface CachedType {
  [key: string]: Promise<any>;
}

export interface ResponseResult<T> {
  Code: number;
  Data: T;
  Message: string;
}

export enum RequestMethodEnum {
  get = 'get',
  post = 'post',
  put = 'put',
  postForm = 'postForm',
  blobGet = 'blobGet',
  blobPost = 'blobPost',
}

export interface IRequestOptions {
  method: RequestMethodEnum;
  data?: any;
  headers?: any;
}
