// 子应用枚举
export enum MicroAppEnum {
  overview = 'overview',
  configcenter = 'configcenter',
  servermanager = 'servermanager',
  workbench = 'workbench',
  knowledge = 'knowledge'
}

// 子应用列表
export const MICRO_APP_LIST = [
  { appKey: MicroAppEnum.overview, appName: '总览' },
  { appKey: MicroAppEnum.configcenter, appName: '配置中心' },
  { appKey: MicroAppEnum.servermanager, appName: 'IT服务管理' },
  { appKey: MicroAppEnum.workbench, appName: '我的工作台' },
  { appKey: MicroAppEnum.knowledge, appName: '知识库' },
];

export const MICRO_APP_CONTAINER = '#micro-frontend-root';
