import { defaultGlobalState, IGlobalState } from '@shared/tandon-plugin/global';
import { createContext } from 'react';

export enum ACTION {
  SAVE_USER = 'SAVE_USER'
}

export interface IContextAction {
  type: ACTION
  data: any
}

export interface IContext extends IGlobalState {
  dispatchContext?: React.Dispatch<IContextAction>
}

export const contextReducer: (prevState: IContext, action: IContextAction) => IContext = (
  prevState,
  { type, ...payload },
): IContext => {
  const { data } = payload;
  switch (type) {
    case ACTION.SAVE_USER:
      return { ...prevState, user: data };
    default:
      return prevState;
  }
};

const mainContext = createContext(defaultGlobalState as IContext);

export const { Provider, Consumer } = mainContext;

export default mainContext;
