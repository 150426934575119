/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { v4 } from 'uuid';
import { AddIcon } from 'tdesign-icons-react';
import { Button, Dialog, Guide } from 'tdesign-react';
import { CreateIncidentForm } from '@shared/tandon-design/create-incident-form';
import { ReportModule, ReportSubModule, reportFrontData } from '@shared/tandon-plugin/report';
import { AccessSource } from '@shared/tandon-design/workbench/constants';
import Context from '@/context';
import { getViewFlag, saveViewFlag } from '@/service/incident';

const {
  REACT_APP_RUNTIME_ENV: runtimeEnv = 'prod',
} = process.env;

const Create: React.FC = () => {
  const buttonRef = React.useRef<HTMLElement>(null);

  const [visible, setVisible] = React.useState(false);

  const [reportedType] = React.useState<Set<String>>(() => new Set());
  const [traceId] = React.useState<string>(() => v4());

  const onReportTrace = (type: string, id?: string) => {
    if (reportedType.has(type)) return;

    reportedType.add(type);
    reportFrontData({
      action: '创建事件单',
      module: ReportModule.WORKBENCH,
      subModule: ReportSubModule.FOUNDER,
      traceId,
      customField1: type,
      customField2: id,
    });
  };

  const onOpen = () => setVisible(true);

  const onClose = () => {
    onReportTrace('取消');
    setVisible(false);
  };

  const onSuccess = (id: string) => {
    onReportTrace('确认', id);
    setVisible(false);
    window.location.href = '/pc/workbench/handler?tab=created';
  };

  return <>
    <Button
      style={{
        marginRight: 16,
        borderRadius: 4,
      }}
      ref={buttonRef}
      onClick={onOpen}
    >
      <AddIcon style={{ paddingTop: 3 }} />创建
    </Button>

    <Dialog
      width={800}
      header="新建事件"
      visible={visible}
      destroyOnClose
      footer={false}
      onClose={onClose}
      closeOnOverlayClick={false}
      style={{ marginTop: '-15vh' }}
    >
      <CreateIncidentForm
        source={AccessSource.Web}
        runtimeEnv={runtimeEnv}
        onReportTrace={onReportTrace}
        onSuccess={onSuccess}
        onCancel={onClose}
      />
    </Dialog>

    <Guiding element={() => buttonRef.current!} />
  </>;
};

export default Create;

const Guiding = (props: { element(): HTMLElement }) => {
  const { user } = React.useContext(Context);
  const userLoaded = !!user?.userId;

  const [step, setStep] = React.useState(-1);

  React.useEffect(() => {
    if (!userLoaded) return;
    getViewFlag('create').then((seen: boolean) => {
        if (!seen) setTimeout(() => setStep(0), 1200);
    });
  }, [userLoaded]);

  return (
    <Guide
      hideCounter
      steps={[{
        element: props.element,
        title: '点此创建单据',
        body: '任意界面，随时随地，一键创建单据。',
        placement: 'bottom',
      }]}
      current={step}
      onChange={setStep}
      finishButtonProps={{ content: '我知道了' }}
      onFinish={() => saveViewFlag('create')}
    />
  );
};
