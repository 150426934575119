import React from 'react';
import logo from '@/assets/svg/no-text-logo.svg';
import s from './index.module.scss';

export default function LoadingPage(): React.ReactElement {
  return (
    <div className={s.loadingBody}>
      <img src={logo} alt="logo" width={200} />
      <div className={s.animateText}>让 管 理 更 精 益</div>
      <div className={s.text}>正为您开通试用版~</div>
    </div>
  );
}
