import { useState, useEffect } from 'react';

export function useTyping() {
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    let timeoutId: string | number | NodeJS.Timeout | undefined;

    const handleKeyDown = () => {
      clearTimeout(timeoutId);
      setIsTyping(true);
    };

    const handleKeyUp = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setIsTyping(false), 500);
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      clearTimeout(timeoutId);
    };
  }, []);

  return isTyping;
}
