import React, { useState } from 'react';
import { Button, DialogPlugin, MessagePlugin } from 'tdesign-react';
import { MessageError } from '@shared/tandon-design/message-error';
import { ReportModule, reportFrontData } from '@shared/tandon-plugin/report';
import { SCENE_OPTIONS, ACCOUNT_OPTIONS } from '@shared/tandon-plugin/init-tenant';
import { InfoCircleFilledIcon } from 'tdesign-icons-react';
import { initTenantCombo } from '@/service/tenant';
import { STUFF_URL } from '@/constants';
import logo from '@/assets/svg/no-text-logo.png';
import { sleep } from '@/utils';
import s from './index.module.scss';

interface DialogProps {
  setShowLoading?: (show: boolean) => void;
  setVisible: (show: boolean) => void;
  visible: boolean;
}

const header = (
  <>
    <InfoCircleFilledIcon className={s.errorIcon} size={16} />
    开通失败
  </>
);
// 开通试用版套餐弹窗内容
export default function DialogContent(props: DialogProps): React.ReactElement {
  const [account, setAccount] = useState<number | null>(null);
  const [scene, setScene] = useState<number | null>(null);

  const { setShowLoading, setVisible } = props;

  // 点击进入腾讯云安灯，立即进入loading页，待接口返回，成功进入工单，失败返回弹窗
  const initTenantComboHandle = () => {
    reportFrontData({
      action: '企业开启试用',
      module: ReportModule.ABOUT_ANDON,
      customField1: 'PC端',
    });
    setVisible(false);
    setShowLoading?.(true);
    const { REACT_APP_TANDON_DOMAIN } = process.env;
    initTenantCombo(account!, scene!)
      .then((rs) => {
        if (rs?.Code === 0) {
          window.location.reload();
          window.location.href = `https://${REACT_APP_TANDON_DOMAIN}/pc/servermanager/manager/things`;
          // 后台Code=2为已开通试用版，此处特殊处理，为了展示MessagePlugin信息和loading页，需要延时reload，否则会页面闪动
        } else if (rs.Code === 2) {
          sleep(1000).then(() => {
            window.location.reload();
          });
          MessagePlugin.success('当前租户已开通试用版');
        } else {
          MessageError(rs?.Message);
          handleErrorDialog();
          setShowLoading?.(false);
        }
      })
      .catch((err) => {
        handleErrorDialog();
        setShowLoading?.(false);
        MessageError(err?.message);
      });
  };
  const handleErrorDialog = () => {
    const myDialog = DialogPlugin({
      header,
      body: '出现错误，请咨询腾讯云安灯客服开通',
      confirmBtn: '立即咨询',
      cancelBtn: null,
      onConfirm: () => {
        myDialog.hide();
        window.location.href = STUFF_URL;
      },
      onClose: () => {
        myDialog.hide();
      },
    });
  };
  const formatOptions = (OptionIconsUrl: string[]) => OptionIconsUrl.map((url, ind: number) => (
    <>
      <img src={url} width={15} height={15} alt="o" key={url} style={{ marginRight: ind === OptionIconsUrl.length - 1 ? 10 : 0 }} />
      {ind === OptionIconsUrl.length - 1 ? '' : '+'}
    </>
  ));
  return (
    <div className={s.dialogBody}>
      <img className={s.andonLogo} src={logo} alt="logo" width={190} height={150} />
      <p className={s.welcomeTitle}>欢迎试用腾讯云安灯</p>
      <p className={s.welcomeTitle} style={{ fontSize: 16, transform: 'translateY(-10px)' }}>Let&apos;s get start here</p>

      <p className={s.optionTitle}>请选择提供服务支持团队的人数：</p>
      <section className={s.inlineOptionContainer}>
        {ACCOUNT_OPTIONS.map(({ value, label }) => (
          <span
            key={value}
            className={s.inlineOption.concat(account === value ? ` ${s.active}` : '')}
            onClick={() => setAccount(account === value ? null : value)}> 
            {label}
          </span>
        ))}
      </section>

      <p className={s.optionTitle}>请选择您的使用场景：</p>
      {SCENE_OPTIONS.map(option => (
        <div
          key={option.OptionKey}
          className={scene === option.OptionKey ? s.activeOption : s.option}
          onClick={() => setScene(scene === option.OptionKey ? null : option.OptionKey)}
        >
          {scene === option.OptionKey ? formatOptions(option.OptionIconsUrlBlue) : formatOptions(option.OptionIconsUrl)}
          {option.OptionLabel}
        </div>
      ))}
      <Button disabled={account === null || scene === null} onClick={() => initTenantComboHandle()}>
        进入腾讯云安灯
      </Button>
    </div>
  );
}
