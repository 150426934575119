import React, { useContext, useLayoutEffect } from 'react';
import { Popup } from 'tdesign-react';
import { UserIcon } from 'tdesign-icons-react';
import { useNavigate, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import copy from 'copy-to-clipboard';
import { initWxConfig } from '@shared/tandon-plugin/wework';
import { PermissionConfig } from '@shared/tandon-plugin/permission';
import { getWeworkOpenUserId, getWeworkTokenObj } from '@shared/tandon-plugin/token';
import defaultUserAvatar from '@/assets/png/user.png';
import Context from '@/context';
import s from './index.module.scss';

interface IRightContentProps {
  companyName: string
}

const isProd = ['prod']?.includes(process.env.REACT_APP_RUNTIME_ENV ?? '');

export default function RightContent(props: IRightContentProps): React.ReactElement {
  const { companyName } = props;
  const { user } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    // 路由会进行replace导致url与企微认证不一致所以做处理
    if (location.pathname !== '/') {
      initWxConfig(['selectExternalContact']);
    }
  }, [location.pathname]);

  const onCopyUser = () => {
    if (isProd) {
      return;
    }
    try {
      copy(JSON.stringify(getWeworkTokenObj()));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={s.rightContent}>
      {user?.permissions?.includes(PermissionConfig.LICENSE_VIEW) && (
        <div
          className={classnames(s.licenseButton, { [s.licenseButtonActive]: location.pathname?.includes('license') })}
          onClick={() => {
            navigate('/configcenter/platform/license');
          }}
        >
          <UserIcon className={s.licenseButtonIcon} size="16px" style={{ color: '#8a8a8a' }} />
        </div>
      )}
      <Popup placement="bottom-left" content={
        <div className={s.rightContentUserDropdownItem} onClick={onCopyUser}>
          <ww-open-data type="userName" openid={getWeworkOpenUserId()} />
          <div className={s.companyName}>@{companyName}</div>
        </div>
      }>
        <img src={user?.avatar || defaultUserAvatar} className={s.userAvatar} alt="头像" />
      </Popup>
    </div>
  );
}
