export const PermissionConfig = {
  // 删除事件单
  INCIDENT_ITEM_DEL: 'incident:item:del',
  // 导出事件单
  INCIDENT_ITEM_EXPORT: 'incident:item:export',
  // 查看全部事件单
  INCIDENT_ALL_VIEW: 'incident:all:view',
  // 启用安灯+
  ANDONPLUS_ANDONPLUS_ADD: 'andonplus:andonplus:add',
  // 关闭安灯+
  ANDONPLUS_ANDONPLUS_MOD: 'andonplus:andonplus:mod',
  // 修改安灯+
  ANDONPLUS_ANDONPLUS_DEL: 'andonplus:andonplus:del',
  // 修改队列
  QUEUE_QUEUE_MOD: 'queue:queue:mod',
  // 添加队列
  QUEUE_QUEUE_ADD: 'queue:queue:add',
  // 删除队列
  QUEUE_QUEUE_DEL: 'queue:queue:del',
  // 修改队列的计时方式
  QUEUE_TIME_MOD: 'queue:time:mod',
  // 修改标签
  TAG_TAG_MOD: 'tag:tag:mod',
  // 添加标签
  TAG_TAG_ADD: 'tag:tag:add',
  // 删除标签
  TAG_TAG_DEL: 'tag:tag:del',
  // 修改服务目录
  CATEGORY_CATEGORY_MOD: 'category:category:mod',
  // 添加服务目录
  CATEGORY_CATEGORY_ADD: 'category:category:add',
  // 删除服务目录
  CATEGORY_CATEGORY_DEL: 'category:category:del',
  // 添加模板字段
  TEMPLATE_FIELD_ADD: 'template:field:add',
  // 修改模板字段
  TEMPLATE_FIELD_MOD: 'template:field:mod',
  // 删除模板字段
  TEMPLATE_FIELD_DEL: 'template:field:del',
  // 创建工作时间
  SCHEDULE_ADD: 'work:time:add',
  // 编辑工作时间
  SCHEDULE_EDIT: 'work:time:mod',
  // 启用/禁用工作时间
  SCHEDULE_DELETE: 'work:time:del',
  // 创建角色
  ROLE_ADD: 'role:role:add',
  // 删除角色
  ROLE_DELETE: 'role:role:del',
  // 编辑角色名称
  ROLE_RENAME: 'role:name:mod',
  // 编辑角色成员列表
  ROLE_MEMBER_MOD: 'role:user:mod',
  // 编辑角色权限集
  ROLE_PERMISSION_MOD: 'role:permission:mod',
  // 查看license
  LICENSE_VIEW: 'license:license:view',
};

export const NO_PERMISSION_TEXT = '无操作权限，请联系管理员开通';
