import { get } from '../request';
import { GetUserInfo, IGlobalState } from './type';

export const defaultGlobalState: IGlobalState = {
  user: {
    usePermission: false,
    permissions: [],
    avatar: '',
    userId: '',
    openId: '',
    corpId: '',
  },
  updateServermenuStatus: '',
  updateQueueStatus: '',
};

/**
 * 获取当前用户信息
 */
export const getUserInfo = (): Promise<GetUserInfo> => get('/api/v1/user/DescribeCurrUser');

export * from './type';
