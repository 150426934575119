/* eslint-disable import/prefer-default-export */
import { getWeworkTenantId } from '@shared/tandon-plugin/token';
import { post, ResponseResult } from '@shared/tandon-plugin/request';
import { IDescribeTenantItem, IDescribeTenantParams, IGetUserPermissionsParams } from './tenant.type';

const userBaseURL = '/api/v1/tenant';
const menuBaseURL = '/api/v1/directory';

/**
 * 获取租户列表
 * @param params
 * @apidocument http://docs.cloudrpa.woa.com/project/37/interface/api/1780
 * @returns
 */
export const getDescribeTenant = (params: IDescribeTenantParams): Promise<ResponseResult<IDescribeTenantItem[]>> => post(`${userBaseURL}/DescribeTenant`, params);

/**
 * 租户初始化
 * @param params
 * @apidocument http://docs.cloudrpa.woa.com/project/37/interface/api/1780
 * @returns
 */
export const initTenantCombo = (account: number, scene: number): Promise<ResponseResult<IDescribeTenantItem[]>> => post(`${userBaseURL}/InitTenantCombo`, { TenantId: getWeworkTenantId(), PersonNumOption: account, InitOption: scene });
/**
 * 查询租户下的服务目录是否存在警示状态
 * @param
 * @apidocument http://doc.tandon.woa.com/project/46/interface/api/5189
 * @returns
 */
export const describeSvcDirWarn = (): Promise<ResponseResult<boolean>> => post(`${menuBaseURL}/DescribeSvcDirWarn`, { TenantId: getWeworkTenantId() });
/**
 * 查看初始化选项列表
 * @param
 * @apidocument http://docs.cloudrpa.woa.com/project/46/interface/api/5976
 * @returns
 */
export const getInitOptions = (): Promise<ResponseResult<IGetUserPermissionsParams[]>> => post(`${userBaseURL}/GetInitOptions`, { TenantId: getWeworkTenantId() });
