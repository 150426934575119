import Aegis from 'aegis-web-sdk';
import { getWeworkCorpid, getWeworkOpenUserId, getWeworkTandonUserId, getWeworkTenantId, getWeworkTenantName, getWeworkUserId } from '../token';

interface ReportPayload {
  event: string
  data: any
}

export const TcAndonAegisIdMap = {
  tandonChatToolbar: {
    prod: 'p0e0bTkgG1O3E7228G',
    dev: '4e8ewH5oJ4ek3mb0JR',
    local: '4e8ewH5oJ4ek3mb0JR',
    testing: '4e8ewH5oJ4ek3mb0JR',
  },
  tandonPc: {
    prod: 'lJeJvhy9JarlVQ4VGa',
    dev: '4e8ewH5oJ4ek3mb0JR',
    local: '4e8ewH5oJ4ek3mb0JR',
    testing: '4e8ewH5oJ4ek3mb0JR',
  },
};

/**
 * 自定义方法上报
 * @param name
 */
export function reportCustomEvent(name: string, ext1: string, ext2?: string): void {
  const aegis = SingletonAegis.getInstance();
  if (!aegis) {
    return;
  }
  aegis.reportEvent({ name, ext1, ext2 });
}

/**
 * 公共上报方法
 * @param payload
 * @returns
 */
export const reportCommonEvent = (payload: ReportPayload): void => {
  const aegis = SingletonAegis.getInstance();
  if (!aegis) {
    return;
  }
  const { data, event } = payload;
  try {
    const dataStr = JSON.stringify({
      ...data,
      OpenUserId: getWeworkOpenUserId(),
      CorpId: getWeworkCorpid(),
      UserId: getWeworkUserId(),
      TandonUserId: getWeworkTandonUserId(),
      TenantId: getWeworkTenantId(),
      TenantName: getWeworkTenantName(),
    });
    aegis.infoAll({
      event,
      data: dataStr,
    });
  } catch (error) {
    aegis.infoAll({
      event,
      data: 'json stringify error',
    });
  }
};

export class SingletonAegis {
  private static aegis: Aegis | null;

  private static aegisId: string;

  static setAegisId(aegisId: string): void {
    if (!this.aegisId && aegisId) {
      this.aegisId = aegisId;
    }
    this.getInstance();
  }

  static getInstance(): Aegis | null {
    if (!this.aegis) {
      this.aegis = SingletonAegis.initAegis();
    }
    return this.aegis;
  }

  private static initAegis(): Aegis | null {
    let aegis: Aegis | null = null;
    if (this.aegisId) {
      aegis = new Aegis({
        id: this.aegisId,
        spa: true,
        uin: getWeworkTandonUserId(),
        speedSample: false,
        api: {
          reportRequest: true,
          resHeaders: ['x-req-id'],
        },
        hostUrl: 'https://rumt-zh.com',
        reportApiSpeed: true, // 接口测速
        reportAssetSpeed: true, // 静态资源测速
        beforeRequest: (data: any) => {
          if (data.logType === 'performance') {
            // 修改：将性能数据的首屏渲染时间改为2s
            const firstScreenTime = data.logs.firstScreenTiming;
            reportCustomEvent('first-screen-time', firstScreenTime);
          }
          return data;
        },
      });
    }
    // @ts-ignore
    window.AndonAegis = aegis;
    return aegis;
  }
}
