import { ReportModule, ReportSubModule } from '@shared/tandon-plugin/report';
import { DirLevel, StatusEnum } from '../constants';
import { IDirTreeItem } from '../interface';

export const parseStyleByStr = (styleStr: string) => {
  const styleObj: any = {};
  if (styleStr) {
    styleStr.split(';').forEach((s: any) => {
      if (!s) return;
      const [name, val] = s.split(':');
      styleObj[name] = val;
    });
  }
  return styleObj;
};

export function formatEventTime(seconds: number): number {
  const minutes = Math.round(seconds / 60);
  if (minutes < 1) {
    return 60;
  }
  return Math.round(minutes * 60 / 10) * 10;
}

export const traverseTreeNode = (data: IDirTreeItem[]) => {
  const deleteIndexArray: number[] = [];
  data.forEach((item, index) => {
    if (item.Status === StatusEnum.enable && item.Children.length > 0) {
      traverseTreeNode(item.Children);
    } else if ((item.Level === DirLevel.first && item.Children.length === 0) || item.Status === StatusEnum.disabled || (item.Level === DirLevel.second && !item.QueueStatus)) {
      // 禁用的节点删除
      deleteIndexArray.push(index);
    }
  });
  // 从后往前删数组的数据
  deleteIndexArray.reverse();
  deleteIndexArray.forEach((item) => {
    data.splice(item, 1);
  });
};

export const isWithin24Hours = (timestamp: number): boolean => {
  const perHour = 60 * 60;
  const now = Date.now() / 1000;
  const diff = now - timestamp;
  const hoursDiff = diff / perHour;
  return hoursDiff <= 24;
};

type AsyncFc = (...args: any[]) => Promise<any>;
/**
 * 加工一个异步函数fn得到目标函数f
 * f负责调用fn，并且如果发起一次新的调用，那么旧的调用结果将会被无视
 * 用于处理竞态请求
 */
export const raced = <T extends AsyncFc>(fn: T) => {
  let raceId = -1;
  return (...args: Parameters<T>) => new Promise((resolve, reject) => {
    // eslint-disable-next-line no-multi-assign
    const id = raceId = Date.now();
    fn(...args)
      .then((res) => {
        if (id === raceId) resolve(res);
      })
      .catch((err) => {
        if (id === raceId) reject(err);
      });
  }) as ReturnType<T>;
};

export const getCurrentModule = (type?: 'knowledge') => {
  if (window.location.pathname.includes('handler')) {
    return {
      module: ReportModule.WORKBENCH,
      subModule: ReportSubModule.HANDLER,
    };
  }
  if (window.location.pathname.includes('chat-toolbar') && type === 'knowledge') {
    return {
      module: ReportModule.CHAT_TOOLBAR,
      subModule: ReportSubModule.KNOWLEDGE,
    };
  }
  if (window.location.pathname.includes('unorganized')) {
    return {
      module: ReportModule.KNOWLEDGE,
      subModule: ReportSubModule.UNORGANIZED,
    };
  }
  if (window.location.pathname.includes('organized')) {
    return {
      module: ReportModule.KNOWLEDGE,
      subModule: ReportSubModule.ORGANIZED,
    };
  }
  return {
    module: ReportModule.INCIDENT,
  };
};
