interface IStorage {
  set(key: string, value: any, days?: number): void;
  get(key: string): any;
  remove(key: string): void;
  clear(): void;
}

const session: IStorage = {
  set(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  },

  get(key: string): any {
    const value = sessionStorage.getItem(key);
    try {
      return value ? JSON.parse(value) : null;
    } catch (error) {
      return value || null;
    }
  },

  remove(key: string): void {
    sessionStorage.removeItem(key);
  },

  clear(): void {
    sessionStorage.clear();
  },
};

const local: IStorage = {
  set(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  },

  get(key: string): any {
    const value = localStorage.getItem(key);
    try {
      return value ? JSON.parse(value) : null;
    } catch (error) {
      return value || null;
    }
  },

  remove(key: string): void {
    localStorage.removeItem(key);
  },

  clear(): void {
    localStorage.clear();
  },
};

export const storage = {
  session,
  local,
};

export class StorageHelper<T> {
  private key: string;

  private store: IStorage;

  private validator: (v: any) => v is T;

  /**
   * 针对单一key，提供getValue saveValue 和 clearValue
   * @param key
   * @param type 默认session storage
   * @param validator 读取数据的时候用来校验，校验失败时，会返回null
   */
  constructor(
    key: string,
    type?: 'session' | 'local',
    validator?: (v: any) => v is T,
  ) {
    this.key = key;
    this.store = storage[type || 'session'];
    this.validator = validator || function noCheck(v: any): v is T {
      return true;
    };
  }

  getValue(): T | null {
    try {
      const value = this.store.get(this.key);
      if (!this.validator(value)) throw new Error('');
      return value;
    } catch (e: any) {
      return null;
    }
  }

  saveValue(v: T) {
    this.store.set(this.key, v);
  }

  clearValue() {
    this.store.remove(this.key);
  }
}
