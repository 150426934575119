import { DialogInstance, DialogPlugin } from 'tdesign-react';
import { storage } from '@shared/tandon-plugin/storage';
import { getLoginStatus } from '../../token';

const isInWework = navigator.userAgent.includes('wxwork/');

const authMap = {
  prod: {
    COPR_ID: 'wwe897e8857c62f0c0', // 服务商corpID
    APP_ID: 'ww343e07ad2ea04a5b', // 腾讯云安灯
    AUTH_STATE: 'WWLogin',
  },
  test: {
    COPR_ID: 'wwe897e8857c62f0c0',
    APP_ID: 'wwae97200607a3619e', // 腾讯云安灯-测试
    AUTH_STATE: 'WWLoginTest',
  },
  dev: {
    COPR_ID: 'wwe897e8857c62f0c0',
    APP_ID: 'ww632bfa106333d01e', // 腾讯云安灯-开发
    AUTH_STATE: 'WWLoginDev',
  },
};

const getEnv = () => {
  const host = window.location.hostname;
  if (host?.includes('dev')) {
    return 'dev';
  } if (host?.includes('test')) {
    return 'test';
  }
  return 'prod';
};

let dialogInstance: DialogInstance | undefined;

// 跳转登录
export const redirectToNoAuthPage = (): void => {
  const onRedirect = () => {
    storage.session.clear();
    if (isInWework) oauthRedirect();
    else ssoRedirect();
  };

  if (!getLoginStatus()) {
    onRedirect();
    return;
  }

  if (!dialogInstance) {
    dialogInstance = DialogPlugin({
      header: ' ',
      body: `登录已过期，${isInWework ? '即将为您重新登录' : '点击确认重新扫码登录'}`,
      onConfirm: onRedirect,
      cancelBtn: null,
      closeBtn: false,
      closeOnEscKeydown: false,
      closeOnOverlayClick: false,
      destroyOnClose: false,
    });
  } else {
    dialogInstance.show();
  }
};

/**
 * 无权限拦截器
 */
export function noAuthInterseptor(): () => void {
  // 添加XHR拦截器
  const originXhrOpen = XMLHttpRequest.prototype.open;
  XMLHttpRequest.prototype.open = function xhrCb(...args: any) {
    this.addEventListener('load', function xhrLoadCb() {
      if (this.status === 401) {
        redirectToNoAuthPage();
      }
    });
    originXhrOpen.apply(this, args);
  };

  // 返回取消拦截钩子
  return function unregister(): void {
    XMLHttpRequest.prototype.open = originXhrOpen;
  };
}

export function rewriteXhr() {
  const unregister = noAuthInterseptor();
  window.addEventListener('beforeunload', unregister, { once: true });
}

declare global {
  interface Window {
    isRefreshing: boolean;
    requests: any[];
  }
}

/** 外部浏览器，需要选择企业或扫码，使用sso登录 */
function ssoRedirect() {
  const { AUTH_STATE, COPR_ID } = authMap[getEnv()];

  const currentUrl = `${window.location.pathname}${window.location.search}`;

  const callbackUrl = `https://andon.tencent.com/wework/login/auth/userinfo?referer_path=${window.btoa(currentUrl)}`;

  const loginBaseUrl = 'https://login.work.weixin.qq.com/wwlogin/sso/login/';

  const params = new URLSearchParams({
    login_type: 'ServiceApp',
    appid: COPR_ID,
    redirect_uri: encodeURI(callbackUrl),
    state: AUTH_STATE,
  });

  window.open(`${loginBaseUrl}?${params.toString()}`, '_self');
}

/** 企微环境，无需选择企业或扫码，使用oauth登录  */
function oauthRedirect() {
  const { APP_ID } = authMap[getEnv()];

  const currentUrl = `${window.location.pathname}${window.location.search}`;

  const callbackUrl = `${window.location.origin}/wework/login/parse/userinfo?referer_path=${window.btoa(currentUrl)}`;

  const loginBaseUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize';

  const params = new URLSearchParams({
    appid: APP_ID,
    redirect_uri: encodeURI(callbackUrl),
    response_type: 'code',
    scope: 'snsapi_privateinfo',
  });

  window.open(`${loginBaseUrl}?${params.toString()}#wechat_redirect`, '_self');
}
