/**
 * @description elem to html
 * @author wangfupeng
 */

import { SlateElement } from '@wangeditor/editor';
import { AttachmentElement } from './custom-types';

// 生成 html 的函数
function attachmentToHtml(elem: SlateElement, _childrenHtml: string): string {
  const { link = '', fileName = '' } = elem as AttachmentElement;
  return `<p data-type="attachment" data-href="${link}" style="cursor:pointer;max-width:100%">
    <a href="${link}" style="color:#0052d9;word-break:break-all">${fileName.slice(13)}</a>
    <svg width="2em" height="2em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style="display: inline-block;width:16px;height:16px;filter: invert(17%) sepia(93%) saturate(5995%) hue-rotate(212deg) brightness(93%) contrast(105%);vertical-align: middle;">
      <path d="M12.2636 5.81387L8.5 9.57746L8.49998 0.5L7.49998 0.500002L7.5 9.57746L3.73641 5.81387L3.02931 6.52098L7.64645 11.1381C7.84171 11.3334 8.15829 11.3334 8.35355 11.1381L12.9707 6.52098L12.2636 5.81387Z" fill="currentColor" fill-opacity="0.9"></path>
      <path d="M2 11V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V11H13V13H3V11H2Z" fill="currentColor" fill-opacity="0.9"></path>
    </svg>
  </p>`;
}

// 配置
const conf = {
  type: 'attachment', // 节点 type ，重要！！！
  elemToHtml: attachmentToHtml,
};

export default conf;
