import { v4 } from 'uuid';
import { reportCustomEvent } from '../aegis';
import { post } from '../request';
import { storage } from '../storage';

export enum ReportModule {
  WORKBENCH = '我的',
  INCIDENT = '事件',
  OVERVIEW = '报表',
  SERVICE_360 = '服务360',
  KNOWLEDGE = '知识库',
  CONFIG_CENTER = '配置',
  ABOUT_ANDON = '关于安灯',
  PRODUCT_VERSION = '产品动态',
  CONTACT_US = '联系安灯',
  CHAT_TOOLBAR = '服务台'
}

export enum ReportSubModule {
  FOUNDER = '我是创建人',
  HANDLER = '我是处理人',
  JINGSHOU = '我是经手人',
  UNORGANIZED = 'AI生成',
  ORGANIZED = '人工整理',
  QUEUE = '队列管理',
  TEMPLATE = '模板配置',
  SCHEDULE = '工作时间配置',
  MENU = '服务目录',
  TAG = '标签管理',
  ANDON_PLUS = '安灯+',
  PERMISSION = '权限管理',
  EVENT_LIST = '单据列表',
  CREATE_EVENT = '我要建单',
  KNOWLEDGE = '知识库',
  XIAO_E = '小鹅摇人',
}

export enum ReportSource {
  PC = 'PC端',
  MOBILE = '移动端',
}

export enum ReportScene {
  WEB = '应用主页',
  GROUP = '群服务台',
  STAFF = '员工服务台',
  WECHAT = '微信客服服务台',
  SINGLE = '企业外单聊服务台',
  ATTACHMENT = '聊天附件栏',
  CHAIN_GROUP = '上下游群聊',
}

interface ReportFrontData {
  action: string // 上报操作
  module: ReportModule // 一级模块
  subModule?: ReportSubModule // 二级模块
  scene?: ReportScene // 场景(默认应用主页)
  traceId?: string // 局部链路ID
  customField1?: string
  customField2?: string
  customField3?: string
  customField4?: string
  customField5?: string
  content?: string
}

export const reportFrontData = (data: ReportFrontData) => {
  if (!data) {
    return;
  }
  let sessionId = storage.session.get('tc_andon_session_id');
  if (!sessionId) {
    sessionId = v4();
    storage.session.set('tc_andon_session_id', sessionId);
  }
  const reportData = {
    sessionId,
    value_of_primary_key: v4(),
    source: getReportSource(),
    scene: ReportScene.WEB,
    userAgent: navigator?.userAgent,
    ...data,
  };
  post('/api/v1/report/FrontData', {
    Chainkey: 'tc_andon_report',
    FormField: JSON.stringify(reportData),
  }).catch((error) => {
    reportCustomEvent('front-data-report-error', error);
  });
};

export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator?.userAgent);
}

export const getReportSource = () => {
  return isMobileDevice() ? ReportSource.MOBILE : ReportSource.PC
};
