import React from 'react';
import { IOptionItem } from '@shared/tandon-design/workbench/interface';
import { CheckTag } from 'tdesign-react';

interface Props {
  disabled?: boolean;
  value: number;
  onChange(val: number): void;
  options: IOptionItem[];
}

export default function PrioritySelector(props: Props): JSX.Element {
  const { options, value, onChange, disabled = false } = props;

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
      {options.map(opt => (
        <CheckTag
          disabled={disabled}
          style={{ ...staticStyle, backgroundColor: value === opt.value ? colorMap[opt.value] : '' }}
          size="large"
          key={opt.value}
          checked={value === opt.value}
          onClick={() => onChange(opt.value as number)}
        >
          {opt.label}
        </CheckTag>
      ))}
      {options.length === 0 && '请联系管理员进行配置'}
    </div>
  );
}

const staticStyle: React.CSSProperties = {
  marginTop: 4,
  marginRight: 10,
  borderRadius: 20,
  padding: '0 24px',
  color: 'black',
};

const colorMap: Record<number, string> = {
  1: 'rgb(160, 204, 117)',
  2: 'rgb(242, 196, 130)',
  3: 'rgb(227, 134, 110)',
};
