import { ResponseResult } from '../../request/interfaces';
import { get, post } from '../../request';
import { IgetSignatureCompanyParams, IgetSignatureCompanyResult, IgetSignatureAppResult, IAccountItem } from '../interface';

/**
 * 获取jssdk企业签名
 * @returns
 */
export const getSignatureCompany = (params: IgetSignatureCompanyParams): Promise<ResponseResult<IgetSignatureCompanyResult>> => get(`/wework/api/signature/company?nonceStr=${params.NonceStr}&timestamp=${params.Timestamp}&authCorpId=${params.AuthCorpId}&pageUrl=${params.PageUrl}`);

/**
 * 获取jssdk企业签名
 * @returns
 */
export const getSignatureCompanyPost = (params: IgetSignatureCompanyParams): Promise<ResponseResult<IgetSignatureCompanyResult>> => post('/wework/api/signature/company', {
  NonceStr: params.NonceStr,
  Timestamp: `${params.Timestamp}`,
  AuthCorpId: params.AuthCorpId,
  PageUrl: params.PageUrl,
});

/**
 * 获取jssdk应用签名
 * @returns
 */
export const getSignatureApp = (params: IgetSignatureCompanyParams): Promise<ResponseResult<IgetSignatureAppResult>> => get(`/wework/api/signature/app?nonceStr=${params.NonceStr}&timestamp=${params.Timestamp}&authCorpId=${params.AuthCorpId}&pageUrl=${params.PageUrl}`);

/**
 * 获取jssdk应用签名
 * @returns
 */
export const getSignatureAppPost = (params: IgetSignatureCompanyParams) => post('/wework/api/signature/app', {
  NonceStr: params.NonceStr,
  Timestamp: `${params.Timestamp}`,
  AuthCorpId: params.AuthCorpId,
  PageUrl: params.PageUrl,
});

/**
 * 获取企业微信客服账号列表
 * @returns
 */
export const getWxKfAccountList = (): Promise<ResponseResult<IAccountItem[]>> => post('/wework/api/kf/account/list', {});
