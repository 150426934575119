import { ROOT_ID, Template, TemplateDictionary } from './type';

/**
 * 根据当前目录，找到对应模板
 * @param templates 全量模板
 * @param categoryPath 当前目录
 * @returns 目录下的模板
 */
export const searchTemplate = (templates: TemplateDictionary, categoryPath: number[]): Template => {
  const searchPath = [ROOT_ID, ...categoryPath].reverse();
  for (const id of searchPath) if (templates[id]?.length) return templates[id];
  return [] as Template;
};

/** 将模板字段的值转为显示的文本 */
export const displayValue = (fieldValue: any): string => {
  if (fieldValue === undefined) return '--';
  if (Array.isArray(fieldValue)) return fieldValue.join(';') || '--';
  return String(fieldValue) || '--';
};
