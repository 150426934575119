import React from 'react';
import logo from '@/assets/svg/new-logo.svg';
import RightContent from './components/header-right';
import Create from './components/create';
import s from './index.module.scss';

interface IBarProps {
  companyName: string
}
/**
 *
 * @returns
 */
export default function Bar(props: IBarProps): React.ReactElement {
  return (
    <div className={s.topBar}>
      <img src={logo} alt="andonlogo" width={200} height={40} className={s.andonLogo} />
      <div className={s.right}>
        <Create />
        <RightContent companyName={props.companyName} />
      </div>
    </div>
  );
}
