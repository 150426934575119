import { reportCustomEvent } from '@shared/tandon-plugin/aegis';
import { MessagePlugin } from 'tdesign-react';

declare global {
  interface Window {
    isShowingMessagePlugin: boolean;
  }
}

/*
* desc：在不同的应用中不是一个单例，所以需要用标识在windows上的字段判断单例是否存在
* func：为了Message.error只弹窗一次封装
*/
export const MessageError = (msg: string): void => {
  if (!msg || window.isShowingMessagePlugin) {
    return;
  }
  window.isShowingMessagePlugin = true;
  MessagePlugin.error(msg);
  reportCustomEvent('show-error-message', msg);
  setTimeout(() => {
    window.isShowingMessagePlugin = false;
    MessagePlugin.closeAll();
  }, 3000);
};
