import axios, { AxiosRequestConfig, Method } from 'axios';
import { cloneDeep } from 'lodash';
import { storage } from '../storage';
import { getWeworkJWTToken } from '../token';
import { CacheOption, RequestMethodEnum, CachedType, IRequestOptions } from './interfaces';

export function get(
  url: string,
  cacheOption: CacheOption = {
    enable: false,
  },
): Promise<any> {
  return requestWithCache(url, { method: RequestMethodEnum.get }, cacheOption)
    .then(res => Promise.resolve(res))
    .catch((e) => {
      // eslint-disable-next-line
      console.error(e);
      return Promise.reject(e);
    });
}

export function blobGet(
  url: string,
  cacheOption: CacheOption = {
    enable: false,
  },
): Promise<any> {
  return requestWithCache(url, { method: RequestMethodEnum.blobGet }, cacheOption)
    .then(res => Promise.resolve(res))
    .catch((e) => {
      // eslint-disable-next-line
      console.error(e);
      return Promise.reject(e);
    });
}

export function post(
  url: string,
  data: Object,
  cacheOption: CacheOption = {
    enable: false,
  },
): Promise<any> {
  return requestWithCache(url, { method: RequestMethodEnum.post, data }, cacheOption)
    .then(res => Promise.resolve(res))
    .catch((e) => {
      // eslint-disable-next-line
      console.error(e);
      return Promise.reject(e);
    });
}

export function put(
  url: string,
  data: Object,
  cacheOption: CacheOption = {
    enable: false,
  },
): Promise<any> {
  return requestWithCache(url, { method: RequestMethodEnum.put, data }, cacheOption)
    .then(res => Promise.resolve(res))
    .catch((e) => {
      // eslint-disable-next-line
      console.error(e);
      return Promise.reject(e);
    });
}

export function postForm(
  url: string,
  data: Object,
  cacheOption: CacheOption = {
    enable: false,
  },
): Promise<any> {
  return requestWithCache(url, { method: RequestMethodEnum.postForm, data }, cacheOption)
    .then(res => Promise.resolve(res))
    .catch((e) => {
      // eslint-disable-next-line
      console.error(e);
      return Promise.reject(e);
    });
}

export function blobPost(
  url: string,
  data: Object,
  cacheOption: CacheOption = {
    enable: false,
  },
): Promise<any> {
  return requestWithCache(url, { method: RequestMethodEnum.blobPost, data }, cacheOption)
    .then(res => Promise.resolve(res))
    .catch((e) => {
      // eslint-disable-next-line
      console.error(e);
      return Promise.reject(e);
    });
}

const cachedRequests: CachedType = {};

function cleanCache(hash: string) {
  return (res?: Response) => {
    delete cachedRequests[hash];
    return res;
  };
}

const getAxiosConfig = (options: IRequestOptions, url: string) => {
  const config: AxiosRequestConfig = {
    method: options.method.replace(/blob|[f|F]orm/g, '').toLowerCase() as Method,
    url,
  };
  if ([RequestMethodEnum.get, RequestMethodEnum.blobGet]?.includes(options.method)) {
    config.headers = {
      'X-Requested-With': 'XMLHttpRequest',
    };
  }
  if ([RequestMethodEnum.post, RequestMethodEnum.blobPost]?.includes(options.method)) {
    config.headers = {
      'Content-Type': 'application/json; charset=utf-8',
      'X-Requested-With': 'XMLHttpRequest',
    };
    if (options.data) {
      config.data = cloneDeep(options.data);
    }
  }
  if ([RequestMethodEnum.blobGet, RequestMethodEnum.blobPost]?.includes(options.method)) {
    config.responseType = 'blob';
  }
  if (options.method === RequestMethodEnum.postForm) {
    config.headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    config.data = options?.data;
  }
  // 添加
  const tandonJwt = getWeworkJWTToken();
  if (tandonJwt) (config.headers as any).Authorization = `Bearer ${tandonJwt}`;
  return config;
};

/**
 * 带有请求缓存队列的request方法
 * 机制：当请求未返回而新的同样的请求又发送过来时，会根据请求类型进行cache
 * 根据RESTful规范制定缓存策略
 * 对于patch，put，delete请求：直接返回pending状态的Promise对象
 * 对于get，post请求：返回sessionStorage或CacheController中的缓存数据
 * @param url
 * @param options
 * @param silent
 * @param cacheOption - 缓存配置
 *  enable：boolean 是否启用缓存
 *  expires：number 缓存有效期
 * @returns
 */
export function requestWithCache(
  url: string,
  options: IRequestOptions,
  cacheOption: CacheOption = {
    enable: false,
    expires: 12 * 60 * 60 * 1000,
  },
): Promise<any> {
  const hash = `${options.method} ${url} ${JSON.stringify(options.data ?? {})}`;
  if (cacheOption?.enable) {
    const cachedRequest = cachedRequests[hash];
    if (
      cachedRequest instanceof Promise
      && [RequestMethodEnum.get, RequestMethodEnum.post, RequestMethodEnum.postForm]?.includes(options.method)
    ) {
      cachedRequest
        .then((data) => {
          if (data?.status === 200 && cacheOption?.expires && cacheOption?.expires > 0) {
            storage.session.set(hash, data.data, cacheOption.expires);
          }
        })
        .catch(() => {});
      return cachedRequest;
    }
    const cache = storage.session.get(hash);
    // get/post cache逻辑
    if (cache) {
      return Promise.resolve(cache);
    }
  }
  const config = getAxiosConfig(options, url);
  cachedRequests[hash] = axios(config);
  return cachedRequests[hash]
    .then((res: any) => {
      // 缓存结果
      if (
        cacheOption.enable
        && [RequestMethodEnum.get, RequestMethodEnum.post, RequestMethodEnum.postForm]?.includes(options.method)
        && res
        && cacheOption?.expires
        && cacheOption?.expires > 0
      ) {
        storage.session.set(hash, res.data, cacheOption?.expires);
      }
      return res.data;
    })
    .finally(() => cleanCache(hash)());
}
