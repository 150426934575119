/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-bitwise */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Button, Cascader, Form, Input, MessagePlugin } from 'tdesign-react';
import { getWeworkTenantId } from '@shared/tandon-plugin/token';
import { storage } from '@shared/tandon-plugin/storage';
import { CreateIncidentFormProps } from './type';
import {
  FieldRender, useTemplateManager, useFieldManager, searchTemplate,
  ComputedTemplateField, TemplateField, CREATE_OPTIONAL, CREATE_REQUIRED,
} from '../custom-template-field';
import RichEditor from '../rich-editor';
import { getEncodeRichContent } from '../rich-editor/utils';
import PrioritySelector from '../workbench/components/process-handle/components/common/priority-selector';
import { MessageError } from '../message-error';
import { traverseTreeNode } from '../workbench/utils';
import { getDescribeDirTree, getFieldEnums } from '../workbench/api';
import { IOptionItem, IServiceCategoryItem } from '../workbench/interface';
import { createIncident } from './api';
import '../custom-template-field/index.scss';

const { FormItem } = Form;

export const INITIAL_KEY = 'tandon__initial-value-on-creation';

export const CreateIncidentForm: React.FC<CreateIncidentFormProps> = (props) => {
  const { isMobile = false, runtimeEnv, categoryId, parentId, source, customerId, onReportTrace, onSuccess, onCancel } = props;
  const [priorityOptions, setPriorityOptions] = useState<IOptionItem[]>([]);
  const [serviceCategory, setServiceCategory] = useState<IServiceCategoryItem[]>([]);
  const [categoryPath, setPath] = useState<number[]>(() => {
    if (categoryId && parentId) return [parentId, categoryId];
    return [-1, -1];
  });
  const { templates } = useTemplateManager();
  const { list: fieldList } = useFieldManager();
  const template = useMemo<ComputedTemplateField[]>(() => {
    const categoryTemplate = searchTemplate(templates, categoryPath);

    const fieldDictionary = fieldList.reduce(
      (acc, cur) => Object.assign(acc, { [cur.id]: cur }),
      {} as Record<string, TemplateField>,
    );

    return categoryTemplate
      .filter(row => fieldDictionary[row.fieldId])
      .filter(row => (row.applyRules & (CREATE_OPTIONAL | CREATE_REQUIRED)))
      .map(row => ({
        ...fieldDictionary[row.fieldId],
        required: (row.applyRules & CREATE_REQUIRED) > 0,
      }));
  }, [
    templates,
    categoryPath[0],
    categoryPath[1],
    fieldList,
  ]);
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();

  const titleRef = useRef(null);

  const onUploading = (isUploading: boolean) => {
    setIsUploading(isUploading);
  };

  // 获取优先级枚举值数据
  const getPriorityEnums = () => {
    getFieldEnums({
      Field: 'Priority',
      TenantID: getWeworkTenantId(),
    }).then((rs) => {
      if (rs.Code === 0) {
        const priorityOptions = rs.Data.Enums.map((item: { Name: any; Value: any; }) => ({ label: item.Name, value: item.Value }));
        setPriorityOptions(priorityOptions);
      } else {
        MessageError(rs.Message);
      }
    })
      .catch((err) => {
        MessageError(err);
      });
  };

  // 获取服务目录
  const getServiceCategory = () => {
    getDescribeDirTree().then((rs) => {
      if (rs?.Code === 0) {
        traverseTreeNode(rs.Data);
        // 进行两次过滤操作，过滤掉第一次递归后一级服务目录下没有二级服务目录的数据
        traverseTreeNode(rs.Data);
        const category = rs.Data.map((item) => {
          const children = item.Children.map(c => ({ label: c.Label, value: c.Value }));
          return {
            label: item.Label,
            value: item.Value,
            children,
          };
        });
        setServiceCategory(category);
      } else {
        MessageError(rs?.Message);
      }
    })
      .catch((err) => {
        MessageError(err.Message);
      });
  };

  const onSubmit = (e: any) => {
    if (e.validateResult !== true) {
      return;
    }
    const { fields = {} } = e;
    const encodeRichContent = getEncodeRichContent(fields.content, '请完善事件详情');
    const templateValue = template.reduce(
      (acc, { id }) => Object.assign(acc, { [id]: fields[id] }),
      {} as Record<string, any>,
    );
    if (!encodeRichContent) {
      return;
    }
    setIsSubmitting(true);
    createIncident({
      Priority: fields.priority,
      Title: fields.title,
      CategoryID: isMobile ? categoryId : fields.categoryId,
      Content: encodeRichContent,
      Source: source,
      CustomerID: customerId,
      ExtFields: JSON.stringify(templateValue),
      ReqLabels: [
        { Name: '详细内容', Main: 1, Key: 'Content', Display: '' },
        { Name: '事件标题', Main: 2, Key: 'Title', Display: '' },
        { Name: '优先级', Main: 2, Key: 'Priority', Display: 'PriorityDisplay' },
        { Name: '服务目录', Main: 2, Key: 'Category', Display: 'CategoryDisplay' },
        ...template.map(({ id, label }) => ({
          Key: id,
          Main: 3,
          Name: label,
          Display: '',
        })),
      ],
    }).then((res) => {
      if (res.Code === 0) {
        MessagePlugin.success('操作成功');
        onSuccess(res.Data.Incident.IncidentID, fields.title);
      } else {
        MessageError(res.Message);
      }
    })
      .finally(() => setIsSubmitting(false));
  };

  useEffect(() => {
    if (!isMobile) {
      onReportTrace('创建事件单');
      getServiceCategory();
    }
    getPriorityEnums();
    onReportTrace('优先级');
  }, []);

  useEffect(() => {
    if (titleRef?.current) {
      setTimeout(() => {
        // @ts-ignore
        titleRef.current.focus();
      }, 200);
    }
  }, [titleRef]);

  useEffect(function initFromSessionStorage() {
    const value = storage.session.get(INITIAL_KEY);
    if (value?.title) form.setFieldsValue({ title: value.title });
    if (value?.content) form.setFieldsValue({ content: value.content });
    storage.session.remove(INITIAL_KEY);
  }, []);

  return (
    <div id="custom-template-container" className={isMobile ? 'custom-template-container--mobile' : ''}>
      <Form
        labelAlign="top"
        labelWidth={200}
        form={form}
        initialData={{
          priority: 1,
        }}
        onSubmit={onSubmit}
      >
        <FormItem label="事件标题" name="title" rules={[{ required: true, message: '必填' }, { max: 60, message: '事件标题长度超过限制' }]}>
          <Input
            ref={titleRef}
            placeholder="请输入事件标题"
            onChange={() => onReportTrace('事件标题')}
          />
        </FormItem>
        <FormItem label="优先级" name="priority" rules={[{ required: true, message: '必填' }]}>
          {/* @ts-ignore */}
          <PrioritySelector options={priorityOptions} />
        </FormItem>
        {
          !isMobile && (
            <FormItem label="服务目录" name="categoryId" rules={[{ required: true, message: '必填' }]}>
              <Cascader
                filterable
                placeholder="请选择服务目录"
                options={serviceCategory}
                onChange={(__, { node }) => {
                  setPath(node!.getPath().map(n => n.value as number));
                  onReportTrace('服务目录');
                }}
              />
            </FormItem>
          )
        }
        {
          template.map(field => (
            <FormItem
              key={field.id}
              name={field.id}
              label={field.label}
              rules={field.required ? [{ required: true, message: '必填', trigger: 'submit' }] : []}
            >
              <FieldRender field={field} />
            </FormItem>
          ))
        }
        <FormItem label="事件详情" name="content" rules={[{ required: true, message: '必填' }]}>
          <RichEditor isMobile={isMobile} onUploading={onUploading} runtimeEnv={runtimeEnv} onChange={() => onReportTrace('事件详情')} />
        </FormItem>
        {
          isMobile ? (
            <FormItem>
              <Button theme="primary" type="submit" disabled={isUploading} loading={isSubmitting}>
                提交
              </Button>
              <Button style={{ marginLeft: 10 }} theme="default" disabled={isUploading} loading={isSubmitting} onClick={onCancel}>
                返回
              </Button>
            </FormItem>
          ) : (
            <FormItem style={{ float: 'right' }}>
              <Button theme="default" type="button" onClick={onCancel}>
                取消
              </Button>
              <Button theme="primary" type="submit" style={{ marginLeft: 10 }} disabled={isUploading} loading={isSubmitting}>
                确定
              </Button>
            </FormItem>
          )
        }
      </Form>
    </div>
  );
};
