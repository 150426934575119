import React from 'react';
import { Navigate } from 'react-router-dom';
import LandingPage from '@/pages/landing-page';
import MicroAppDiv from '@/components/micro-app-div';
import ExpiredPage from '@/pages/expired-page';
import TerminationPage from '@/pages/termination-page';

// 租户服务正常状态下路由集合
export const NORMAL_ROUTES = [
  {
    path: '/overview/*',
    element: <MicroAppDiv />,
  },
  {
    path: '/workbench/*',
    element: <MicroAppDiv />,
  },
  {
    path: '/configcenter/*',
    element: <MicroAppDiv />,
  },
  {
    path: '/servermanager/*',
    element: <MicroAppDiv />,
  },
  {
    path: '/knowledge/*',
    element: <MicroAppDiv />,
  },
  {
    path: '/tbpm/*',
    element: <MicroAppDiv />,
  },
  {
    path: '/about-andon',
    element: <LandingPage />,
  },
  {
    path: '/*',
    element: <Navigate to="/workbench/handler" replace />,
  },
];

// 租户服务过期状态下路由集合
export const EXPIRED_ROUTES = [
  {
    path: '/expired-page',
    element: <ExpiredPage />,
  },
  {
    path: '/*',
    element: <Navigate to="/expired-page" replace />,
  },
];

// 租户服务被手动终止时路由集合
export const TERMINATION_ROUTES = [
  {
    path: '/termination-page',
    element: <TerminationPage />,
  },
  {
    path: '/*',
    element: <Navigate to="/termination-page" replace />,
  },
];

// 租户服务为未开通套餐状态或试用版过期
export const ONACTIVATED_ROUTES = [
  {
    path: '/about-andon',
    element: <LandingPage />,
  },
  {
    path: '/*',
    element: <Navigate to="/about-andon" replace />,
  },
];
