import React from 'react';
import dayjs from 'dayjs';
import { Checkbox, DatePicker, Radio, Select, Textarea, InputNumber, Input, DateRangePicker } from 'tdesign-react';
import { FieldType as ComponentType, TemplateField } from '../type';
import CustomInput from './custom-input';

type Props = {
  field: TemplateField;
  [other: string]: any;
};

/** 渲染一个模板字段 */
export const FieldRender: React.FC<Props> = ({ field: { type, props: componentProps }, ...rest }) => {
  const FieldComponents: Record<ComponentType, React.FC<any>> = {
    [ComponentType.Input]: CustomInput,
    [ComponentType.Textarea]: Textarea,
    [ComponentType.Radio]: Radio.Group,
    [ComponentType.Checkbox]: Checkbox.Group,
    [ComponentType.Select]: Select,
    [ComponentType.DatePicker]: DatePicker,
    [ComponentType.InputNumber]: InputNumber,
  };
  
  /** 不同类型字段对应的组件的内置props */
  const componentDefaultProps: Record<ComponentType, { [key: string]: any }> = {
    [ComponentType.Input]: {},
    [ComponentType.Textarea]: {},
    [ComponentType.Radio]: {},
    [ComponentType.Checkbox]: {},
    [ComponentType.Select]: {},
    [ComponentType.DatePicker]: {
      enableTimePicker: true,
      format: 'YYYY/MM/DD HH:mm',
      valueType: 'YYYY/MM/DD HH:mm',
      popupProps: {
        attach: '#custom-template-container',
      },
    },
    [ComponentType.InputNumber]: {},
  };

  const Component = FieldComponents[type];
  if (!Component) return null;

  const defaultProps = componentDefaultProps[type] || {};

  return <Component {...defaultProps} {...componentProps} {...rest} />;
};

/** 把一个模板字段作为筛选项 */
export const FieldFilter: React.FC<Props> = ({ field: { type, props: componentProps }, ...rest }) => {
  const componentMap: Record<ComponentType, React.FC<any>> = {
    [ComponentType.Input]: Input,
    [ComponentType.Textarea]: Input,
    [ComponentType.Radio]: Select,
    [ComponentType.Checkbox]: Select,
    [ComponentType.Select]: Select,
    [ComponentType.DatePicker]: DateRangePicker,
    [ComponentType.InputNumber]: InputNumber,
  };
  
  /** 不同类型字段对应的筛选组件的内置props */
  const componentDefaultProps: Record<ComponentType, { [key: string]: any }> = {
    [ComponentType.Input]: { placeholder: '请输入' },
    [ComponentType.Textarea]: { placeholder: '请输入' },
    [ComponentType.Radio]: { multiple: true, clearable: true, minCollapsedNum: 1, placeholder: '请选择' },
    [ComponentType.Checkbox]: { multiple: true, clearable: true, minCollapsedNum: 1, placeholder: '请选择' },
    [ComponentType.Select]: { multiple: true, clearable: true, minCollapsedNum: 1, placeholder: '请选择' },
    [ComponentType.DatePicker]: {
      placeholder: ['请选择', '请选择'],
      enableTimePicker: true,
      allowInput: true,
      clearable: true,
      valueType: 'YYYY/MM/DD HH:mm:ss',
      presets: {
        最近一个月: [dayjs().subtract(1, 'months').startOf('date'), dayjs().subtract(1, 'days').endOf('date')],
        最近一周: [dayjs().subtract(6, 'day').startOf('date'), dayjs().endOf('date')],
        今天: [dayjs().startOf('date'), dayjs().endOf('date')],
      },
    },
    [ComponentType.InputNumber]: { style: { width: '100%' }, placeholder: '请输入'  },
  };

  const Component = componentMap[type];
  if (!Component) return null;

  const defaultProps = componentDefaultProps[type] || {};

  return <Component {...componentProps} {...defaultProps} {...rest} />;
};
