import qs from 'querystring';
import { MessagePlugin } from 'tdesign-react';
import { MessageError } from '@shared/tandon-design/message-error';
import { ResponseResult, get, post } from '@shared/tandon-plugin/request';
import { getWeworkCorpid, getWeworkTenantId } from '@shared/tandon-plugin/token';
import { CreateItemReq, CreateItemRsp, DescribeAppAuthorizationsReq, DescribeAppAuthorizationsRsp, DescribeProjectsReq, DescribeProjectsRsp, DescribeRelateTicketRsp, DescribeUsersQueuesResult, GetEnumListReq, GetEnumListRsp, GetKnowledgeReq, GetKnowledgeRsp, GetOnlyCheckUsersResult, GetUserQueuesResult, IDirTreeItem, IGetFieldEnumsParams, IIGetFieldEnumResult, IncidentReqLabel, OperateIncidentReq, SearchKnowledgeReq, SearchKnowledgeRsp, UserJoinQueue } from '../interface';

const codeParser = ({ Code, Data, Message }: ResponseResult<any>) => {
  if (Code === 0) return Data;
  MessageError(Message);
  throw new Error();
};

export const getIncidentData = (incidentId: any, source: string) => post(`/api/v1/incident/GetIncident?IncidentID=${incidentId}`, {
  IncidentID: incidentId,
  Source: source,
});

/** 获取事件单的一次性外部评价链接 */
export const getOuterReviewLink = (incidentId: string) => post(
  '/api/v1/service/comment/GetURL',
  {
    incident_id: incidentId,
    tenant_id: getWeworkTenantId(),
  },
)
  .then(({ Code, Message, Data }) => {
    if (Code !== 0) throw new Error(Message);
    return Data.url as string;
  })
  .catch((e: any) => {
    MessageError(e?.message || '出现异常，请重新复制');
    return null;
  });

/** 检测外部评价链接中的code是否有效 */
export const checkOuterReviewLinkCode = (code: string) => post('/api/v1/service/comment/Check', { code })
  .then(({ Code, Message, Data }) => {
    if (Code !== 0) throw new Error(Message);
    if (Data.status as number !== 0) throw new Error('本单据已完成评价，链接失效');
    return true;
  })
  .catch((e: any) => {
    MessageError(e?.message || '本单据已完成评价，链接失效');
    return false;
  });

/** 提交外部评价 */
export const submitOuterReview = (params: { code: string; rate: number; comment: string, ReqLabels: IncidentReqLabel[] }) => post(
  '/api/v1/service/comment/Submit',
  params,
)
  .then(({ Code, Message }) => {
    if (Code !== 0) throw new Error(Message);
    MessagePlugin.success('评价成功');
    return true;
  })
  .catch((e: any) => {
    MessageError(e?.message || '提交评价失败');
    return false;
  });

export const getOlaData = (incidentId: any) => get(`/api/v1/ola_countdown/GetOLACountdown?IncidentID=${incidentId}`);

/**
 * 查询枚举字段
 * @param
 * @returns
 * @apidocument http://doc.tandon.woa.com/project/37/interface/api/1016
 */
export const getFieldEnums = (params: IGetFieldEnumsParams): Promise<ResponseResult<IIGetFieldEnumResult>> => post('/api/v1/incident/GetFieldEnums', params);

/**
 * 以树结构获取租户下的全量服务目录
 * @param
 * @returns
 * @apidocument http://doc.tandon.woa.com/project/46/interface/api/4334
 */
export const getDescribeDirTree = (): Promise<ResponseResult<IDirTreeItem[]>> => get(`/api/v1/directory/DescribeDirTree?TenantId=${getWeworkTenantId()}`);

export const describeQueues = (): Promise<ResponseResult<GetUserQueuesResult[]>> => post(`/api/v1/user/DescribeQueues?TenantId=${getWeworkTenantId()}`, {});

const userJoinQueueCache = new Map<string, Promise<ResponseResult<UserJoinQueue[]> & { Total: number }>>();
/** 查询 处理人x队列 关系表 */
export const describeUserJoinQueue = (keyword: string, pageNum: number, pageSize: number): Promise<ResponseResult<UserJoinQueue[]> & { Total: number }> => {
  const key = qs.stringify({ keyword, pageNum, pageSize });

  const cachedResult = userJoinQueueCache.get(key);

  if (cachedResult !== undefined) return cachedResult;

  const p = post('/api/v1/user/DescribeUsersAtQueues', {
    Query: keyword,
    Offset: (pageNum - 1) * pageSize,
    Limit: pageSize,
  });
  userJoinQueueCache.set(key, p);
  return p;
};

/**
 * 查询用户的队列信息
 * @param UserIds：
 * @wiki http://docs.cloudrpa.woa.com/project/46/interface/api/2247
 * @returns
 */
export const describeUsersQueues = (params?: null | { UserIds: string[] }): Promise<ResponseResult<DescribeUsersQueuesResult[]>> => post('/api/v1/user/DescribeUsersQueues', params || {});

const MODIFIED_BY_OTHERS = -2004;
/**
 * 获取有查看应用权限的用户列表
 * @param
 * @returns
 */
export const getOnlyCheckUsers = (): Promise<ResponseResult<GetOnlyCheckUsersResult>> => get(`/wework/api/corp/scope/users?Corpid=${getWeworkCorpid()}`);

/**
 * 提交事件表单操作
 * @param params
 */
export const handleOperateIncident = (params: OperateIncidentReq) => post('/api/v1/incident/OperateIncident', {
  ...params,
  Tags: params.Tags?.length === 0 ? ['-1'] : params.Tags, // 基于后端框架限制，需要在空数组情况下转为['-1'] @lizhenli
}).then((res) => {
  if (res.Code === MODIFIED_BY_OTHERS) setTimeout(() => window.location.reload(), 2000);
  return res;
});

/**
 * 删除事件单
 */
export const deleteIncident = (params: OperateIncidentReq) => post('/api/v1/incident/DelIncident', params);

/**
 * 取消关联单据
 */
export const cancelRelation = (id: number) => post('/api/v1/plugin/CancelRelation', { Id: id });

/**
 * 获取关联单据列表
 */
export const getRelationTicketList = (incidentId: string): Promise<ResponseResult<DescribeRelateTicketRsp>> => post('/api/v1/plugin/DescribeItems', { IncidentId: incidentId });

/**
 * 创建关联单据
 */
export const createRelationTicket = (params: CreateItemReq): Promise<ResponseResult<CreateItemRsp>> => post('/api/v1/plugin/CreateItem', params);

/**
 * 获取应用授权信息
 */
export const describeAppAuthorizations = (params: DescribeAppAuthorizationsReq): Promise<ResponseResult<DescribeAppAuthorizationsRsp>> => post('/api/v1/plugin/DescribeAppAuthorizations', params);

/**
 * 获取应用关联项目
 */
export const describeProjects = (params: DescribeProjectsReq): Promise<ResponseResult<DescribeProjectsRsp>> => post('/api/v1/plugin/DescribeProjects', params);

/**
 * 获取关联应用优先级
 */
export const getEnumList = (params: GetEnumListReq): Promise<ResponseResult<GetEnumListRsp>> => post('/api/v1/plugin/GetEnumList', params);

/**
 * 搜索知识列表
 */
export const searchKnowledge = (params: SearchKnowledgeReq): Promise<ResponseResult<SearchKnowledgeRsp>> => post('/api/v1/knowledge/search', params);

/**
 * 搜索知识详情
 */
export const getKnowledge = (params: GetKnowledgeReq): Promise<ResponseResult<GetKnowledgeRsp>> => post('/api/v1/knowledge/get', params);

/** 获取标签相关配置 */
export const getTagConfig = (): Promise<{ setting: string }> => post('/api/v1/tag/setting/get', {}).then(codeParser);

const KEY_TOOL_ORDER = 3;

/** 获取 详情页右下角 Tab顺序 */
export const getToolOrder = () => post('/api/v1/incident/GetCustomConfig', {
  TenantID: getWeworkTenantId(),
  Type: KEY_TOOL_ORDER,
})
  .then(({ Code, Message, Data }: ResponseResult<{ Value: string }>) => {
    if (Code !== 0) throw new Error(Message);
    const value = Data.Value;
    return value ? JSON.parse(value) as string[] : null;
  })
  .catch(err => {
    MessageError(err.message);
    return null;
  });

/** 保存 详情页右下角 Tab顺序 */
export const saveToolOrder = (value: string[]) => post('/api/v1/incident/SaveCustomConfig', {
  TenantID: getWeworkTenantId(),
  Type: KEY_TOOL_ORDER,
  Value: JSON.stringify(value),
})
  .then(({ Code, Message }: ResponseResult<{}>) => {
    if (Code !== 0) throw new Error(Message);
    return true;
  })
  .catch(err => {
    MessageError(err.message);
    return false;
  });