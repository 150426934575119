import React from 'react';
import { NoAuth } from '@shared/tandon-design/no-auth';
import s from './index.module.scss';

export default function TerminationPage(): React.ReactElement {
  return (
    <div className={s.terminationPageContainer}>
      <NoAuth describe="您的套餐已关闭" eventDescribe="详情咨询>" />
    </div>
  );
}
