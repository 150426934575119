import React, { useState, useEffect, useReducer, useMemo } from 'react';
import {
  start,
  registerMicroApps,
  addGlobalUncaughtErrorHandler,
  initGlobalState,
  MicroAppStateActions,
  LoadableApp,
} from 'qiankun';
import { useLocation } from 'react-router-dom';
import { NotificationPlugin } from 'tdesign-react';
import { redirectToNoAuthPage } from '@shared/tandon-plugin/request';
import { getWeworkCorpid } from '@shared/tandon-plugin/token';
import { MessageError } from '@shared/tandon-design/message-error';
import { getUserInfo, defaultGlobalState } from '@shared/tandon-plugin/global';
import { MESSAGE_DURING_TIME, ServiceStatusEnum } from '@/constants';
import { getDescribeTenant } from '@/service/tenant';
import { reportCustomEvent } from '@shared/tandon-plugin/aegis';
import { describeQueuesCnt } from './service/user';
import { ACTION, Provider, contextReducer } from './context';
import Router from './router';
import microAppConfig from './micro-app';
import TopBar from './components/top-bar';
import LeftMenu from './components/left-menu';
import MicroDevMode from './components/micro-dev-mode';
import './App.css';

const isProd = process.env.REACT_APP_RUNTIME_ENV === 'prod';

// 加载错误提示
addGlobalUncaughtErrorHandler((event: any) => {
  const { type, message } = event ?? {};
  if (type === 'error' && message?.includes('Uncaught TypeError: application ')) {
    NotificationPlugin.error({
      title: '加载错误',
      content: message,
      placement: 'top-right',
      duration: MESSAGE_DURING_TIME,
      closeBtn: true,
    });
  }
});
/**
 * main应用的入口
 * @returns
 */
function App(): React.ReactElement {
  const [initContext, dispatchContext] = useReducer(contextReducer, defaultGlobalState);
  const [serviceStatus, setServiceStatus] = useState(ServiceStatusEnum.normal);
  const [updateQueueStatus, setUpdateQueueStatus] = useState<boolean>(false);
  const [updateMenuStatus, setUpdateMenuStatus] = useState<boolean>(false);
  const [queueStatus, setQueueStatus] = useState<boolean>(false);
  const [isTrial, setIsTrial] = useState<boolean>(true);
  const [companyName, setCompanyName] = useState('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isHideTopBar = searchParams.get('hideTopBar') === 'true';
  const isHideLeftMenu = searchParams.get('hideLeftMenu') === 'true';

  const showTopBar = useMemo(() => serviceStatus === ServiceStatusEnum.normal && !isHideTopBar, [serviceStatus, isHideTopBar]);
  const showLeftMenu = useMemo(() => serviceStatus === ServiceStatusEnum.normal && !isHideLeftMenu, [serviceStatus, isHideLeftMenu]);

  // 生成上报名称
  const getAppName = (url: string, time: number): string => {
    if (String(url)?.includes('tandon-fe-pc')) {
      const strs = url.split('/').slice(3)
        .join('-');
      return `${strs}-${time}`;
    }
    return `${url}-${time}`;
  };

  useEffect(() => {
    // @hack 启动子应用,解决企微工作台原地刷新子应用容器未挂载问题
    // 注册子应用
    registerMicroApps(microAppConfig, {
      async afterMount(app) {
        window.dispatchEvent?.(new CustomEvent<Partial<LoadableApp<{}>>>('tandon:micro-app-mounted', { detail: { name: app.name } }));
      },
    });
    window.__POWERED_BY_QIANKUN_PARENT__ = true;
    start({
      sandbox: {
        experimentalStyleIsolation: true,
      },
      singular: true,
      // prefetch: false,
      async fetch(url, ...args): Promise<any> { // 拦截fetch
        const startTime = Date.now();
        const response = await window.fetch(url, ...args);
        const endTime = Date.now();
        const time = (endTime - startTime) / 1000;
        const appName = getAppName(String(url), time);
        reportCustomEvent('source-fetch', appName, String(time));
        if (time > 3) {
          reportCustomEvent('source-timeout', appName, String(time));
        }
        if (!response.ok && response.status === 401) {
          redirectToNoAuthPage();
        }
        if (response.status >= 400) {
          reportCustomEvent('source-error', appName, String(response.status));
        }
        return response;
      },
    });
  }, []);

  useEffect(() => {
    // 初始化 state
    const actions: MicroAppStateActions = initGlobalState(defaultGlobalState);
    actions.onGlobalStateChange((state, prev) => {
      if (state.user !== prev.user) {
        dispatchContext?.({
          type: ACTION.SAVE_USER,
          data: state.user,
        });
      }
      // 更新服务目录菜单状态
      if (state.updateServermenuStatus !== prev.updateServermenuStatus) {
        setUpdateMenuStatus(!updateMenuStatus);
      }
      // 更新队列管理菜单状态
      if (state.updateQueueStatus !== prev.updateQueueStatus) {
        setUpdateQueueStatus(!updateQueueStatus);
      }
    });
    getUserTenant();
    getUserData(actions);
  }, []);

  // 子应用变化更新状态 需要根据接口确定status
  useEffect(() => {
    setQueueMenuStatus();
  }, [updateQueueStatus]);

  const setQueueMenuStatus = async () => {
    try {
      const getQueueStatusResult = await describeQueuesCnt({
        OnlyEmpty: 1,
      });
      if (getQueueStatusResult.Code === 0) {
        setQueueStatus(!!getQueueStatusResult.Data?.length);
      } else {
        MessageError(getQueueStatusResult.Message);
      }
    } catch (e: any) {
      MessageError(e);
    }
  };

  const getUserData = async (actions: MicroAppStateActions) => {
    const { Code, UserData, Message, Permissions = [], Switch } = await getUserInfo();
    if (Code === 0) {
      actions.setGlobalState({ user: {
        permissions: Permissions,
        usePermission: Switch === 1,
        avatar: UserData.Avatar,
        openId: UserData.UserOpenId,
        corpId: UserData.UserCorpId,
        userId: UserData.UserId,
      } });
    } else {
      MessageError(Message);
    }
  };

  const getUserTenant = () => {
    getDescribeTenant({
      CorpId: getWeworkCorpid(),
      Page: 1,
      PageSize: 1,
    })
      .then((rs) => {
        if (rs?.Code === 0 && rs?.Data?.length > 0) {
          const item = rs?.Data[0];
          setServiceStatus(item?.ServiceStatus);
          setIsTrial(item?.ComboInUseDetail?.IsTrial);
          setCompanyName(item?.Name);
        } else {
          MessageError(rs?.Message);
        }
      })
      .catch((err) => {
        MessageError(err);
      });
  };

  return (
    <Provider value={{ ...initContext, dispatchContext }}>
      <div className="App">
        {
          showTopBar && (
            <TopBar companyName={companyName} />
          )
        }
        <div className="menu-and-content" style={{ height: showTopBar ? 'calc(100% - 60px)' : '100%' }}>
          {showLeftMenu && <LeftMenu updateMenuStatus={updateMenuStatus} queueStatus={queueStatus} />}
          <Router serviceStatus={serviceStatus} isTrial={isTrial} />
          {!isProd && <MicroDevMode />}
        </div>
      </div>
    </Provider>
  );
}

export default App;
