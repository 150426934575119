/**
 * @description render elem
 * @author wangfupeng
 */

import { h, VNode } from 'snabbdom';
import { DomEditor, IDomEditor, SlateElement, SlateTransforms } from '@wangeditor/editor';
import { MessageError } from '@shared/tandon-design/message-error';
import { AttachmentElement } from './custom-types';

function renderAttachment(elem: SlateElement, children: VNode[] | null, editor: IDomEditor): VNode {
  // 当前节点是否选中
  const selected = DomEditor.isNodeSelected(editor, elem);
  // 构建 vnode
  const { fileName = '', link = '' } = elem as AttachmentElement;
  const path = DomEditor.findPath(editor, elem); // 查找节点的位置

  const previewIconVnode = h(
    'span',
    {
      style: {
        color: '#0052D9',
        cursor: 'pointer',
        marginRight: '10px',
        fontSize: '12px',
        float: 'left',
      },
      on: {
        click() {
          if (link) {
            window.open(link, '_blank');
          } else {
            MessageError('预览地址不存在，请重新上传');
          }
        },
      },
    },
    '预览',
  );

  const closeIconVnode = h(
    'span',
    {
      style: {
        color: '#0052D9',
        cursor: 'pointer',
        marginLeft: '5px',
        fontSize: '12px',
        float: 'right',
      },
      on: {
        click() {
          SlateTransforms.removeNodes(editor, { at: path }); // 删除节点
          editor.restoreSelection(); // 恢复选区
        },
      },
    },
    '删除',
  );

  const vnode = h(
    'div',
    {
      props: {
        contentEditable: false, // 不可编辑
      },
      style: {
        margin: '3px',
        border:
          selected
            ? '2px solid var(--w-e-textarea-selected-border-color)' // wangEditor 提供了 css var https://www.wangeditor.com/v5/theme.html
            : '2px solid transparent',
        borderRadius: '3px',
        padding: '5px 10px',
        backgroundColor: '#f1f1f1',
        userSelect: 'none',
        maxWidth: '100%',
        wordBreak: 'break-all',
        lineHeight: '24px',
      },
    },
    [
      previewIconVnode,
      fileName.slice(13),
      closeIconVnode,
    ],
  );

  return vnode;
}

const conf = {
  type: 'attachment',
  renderElem: renderAttachment,
};

export default conf;
