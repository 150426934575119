/* eslint-disable class-methods-use-this */
import { DomEditor, IDomEditor, SlateRange, IButtonMenu, t } from '@wangeditor/editor';
import { IUploadConfigForAttachment } from './config';
import $ from '../../utils/dom';
import { ICustomEditorConfig, uploadAttachments } from './helper';
import { ATTACHMENT_SVG } from '../../constants/icon-svg';

class UploadAttachmentMenu implements IButtonMenu {
  readonly title = t('attachment.upload');

  readonly iconSvg = ATTACHMENT_SVG;

  readonly tag = 'button';

  getValue(_editor: IDomEditor): string | boolean {
    return '';
  }

  isActive(_editor: IDomEditor): boolean {
    return false;
  }

  exec(editor: IDomEditor, _value: string | boolean) {
    const { allowedFileTypes = [] } = this.getMenuConfig(editor);

    // 设置选择文件的类型
    let acceptAttr = '';
    if (allowedFileTypes.length > 0) {
      acceptAttr = `accept="${allowedFileTypes.join(', ')}"`;
    }

    // 添加 file input（每次重新创建 input）
    const $body = $('body');
    const $inputFile = $(`<input type="file" ${acceptAttr} multiple/>`);
    $inputFile.hide();
    $body.append($inputFile);
    $inputFile.click();
    // 选中文件
    $inputFile.on('change', () => {
      const { files } = $inputFile[0] as HTMLInputElement;
      uploadAttachments(editor, files); // 上传文件
    });
  }

  isDisabled(editor: IDomEditor): boolean {
    const { selection } = editor;
    if (selection == null) return true;
    if (SlateRange.isExpanded(selection)) return true; // 选区非折叠，禁用

    const selectedElems = DomEditor.getSelectedElems(editor);

    const hasVoidElem = selectedElems.some(elem => editor.isVoid(elem));
    if (hasVoidElem) return true; // 选中了 void 元素，禁用

    const hasPreElem = selectedElems.some(elem => DomEditor.getNodeType(elem) === 'pre');
    if (hasPreElem) return true; // 选中了 pre 原则，禁用

    return false;
  }

  private getMenuConfig(editor: IDomEditor): IUploadConfigForAttachment {
    const config = editor.getConfig() as ICustomEditorConfig;
    return config.CUSTOM_CONF.uploadAttachment;
  }
}

export default UploadAttachmentMenu;
