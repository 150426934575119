import { MessageError } from '@shared/tandon-design/message-error';

export const getEncodeRichContent = (content: string, emptyText?: string): string | null => {
  if (!content) {
    MessageError(emptyText || '');
    return null;
  }
  const richContent = content.replace(/<p><br><\/p>/g, '');
  const richText = window.wangEditorText?.trim();
  const isEmptyText = !richText || /^(&nbsp;)+$/.test(richText);
  if (emptyText && (!richContent || (isEmptyText && !richContent?.includes('img') && !richContent?.includes('attachment')))) {
    MessageError(emptyText);
    return null;
  }
  const encodeRichContent = encodeMenuName(richContent);
  return encodeRichContent;
};

export const decodeMenuName = (str: string): string => {
  const map: { [key: string]: string } = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&#92;': '\\',
    '&#39;': '\'',
    '&#34;': '"',
    '&quot;': '"',
  };
  return str?.replace(/&(amp|lt|gt|#92|#39|#34|quot);/g, match => map[match]) || '';
};

export const encodeMenuName = (str: string): string => {
  const map: { [key: string]: string } = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
  };
  return str?.replace(/[&<>"']/g, match => map[match]) || '';
};
