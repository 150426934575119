/* eslint-disable no-param-reassign */
import React from 'react';
import { getTemplateConfig, saveTemplateConfig } from '../api';
import { TemplateDictionary } from '../type';

export type TemplateManager = {
  templates: TemplateDictionary,
  key: number;
  loading: boolean;
  error: boolean;
  save(newTemplates: TemplateDictionary): Promise<boolean>;
  refetch(): void;
};

/** 获取所有模板 */
export default function useTemplateManager(): TemplateManager {
  const [templates, setTemplates] = React.useState<TemplateDictionary>({});
  const [key, setKey] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const fetchData = () => {
    setLoading(true);
    getTemplateConfig(true)
      .then(({ templates }) => {
        setTemplates(templates);
        setKey(+new Date());
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  React.useEffect(fetchData, []);

  const save = async (newTemplates: TemplateDictionary) => {
    setLoading(true);
    const ok = await saveTemplateConfig({ templates: newTemplates }).atleast(400);
    setLoading(false);
    if (ok) {
      setTemplates(newTemplates);
      setKey(+new Date());
    }
    return ok;
  };

  return { templates, key, loading, error, save, refetch: fetchData };
}
