import { post, get } from '@shared/tandon-plugin/request';
import { MessageError } from '../message-error';
import { TemplateField, TemplateConfig, FieldType } from './type';

/**
 * 查询字段池
 */
export const getFieldList = async (exposeError?: boolean): Promise<TemplateField[]> => get('/tbpm/console/api/meta/get?code=incidents&type=3')
  .then(({ Response: { Data, Error } }) => {
    if (Error && Error.Code !== 0) throw MessageError(Error.Message || '未知错误，请联系研发人员');

    if (Data === null) return []; // 未初始化时，为null
    const arr: TemplateField[] = JSON.parse(Data.jsonSchema);

    return arr
      .filter(_ => !!_
        && _.id && typeof _.id === 'string'
        && _.label && typeof _.label === 'string'
        && Object.values(FieldType).includes(_.type));
  })
  .catch((e) => {
    if (exposeError) throw e;
    return [];
  });

/**
 * 保存字段池
 */
export const saveFieldList = async (fields: TemplateField[]): Promise<boolean> => post(
  '/tbpm/console/api/meta/save',
  {
    code: 'incidents',
    type: 3,
    jsonSchema: JSON.stringify(fields),
  },
)
  .then(({ Response: { Error } }) => {
    if (Error && Error.Code !== 0) throw MessageError(Error.Message || '未知错误，请联系研发人员');
    return true;
  })
  .catch(() => false);

/**
 * 查询模板配置
 */
export const getTemplateConfig = async (exposeError?: boolean): Promise<TemplateConfig> => get('/tbpm/console/api/meta/get?code=incidents&type=13')
  .then(({ Response: { Data, Error } }) => {
    if (Error && Error.Code !== 0) throw MessageError(Error.Message || '未知错误，请联系研发人员');

    if (Data === null) return { templates: {} }; // 未初始化时，为null
    const config: TemplateConfig = JSON.parse(Data.jsonSchema);

    for (const tpl of Object.values(config.templates)) if (!Array.isArray(tpl)) throw new Error('模板格式错误');

    return config;
  })
  .catch((e) => {
    if (exposeError) throw e;
    return { templates: {} };
  });

export const saveTemplateConfig = async (value: TemplateConfig): Promise<boolean> => post(
  '/tbpm/console/api/meta/save',
  {
    code: 'incidents',
    type: 13,
    jsonSchema: JSON.stringify(value),
  },
)
  .then(({ Response: { Error } }) => {
    if (Error && Error.Code !== 0) throw MessageError(Error.Message || '未知错误，请联系研发人员');
    return true;
  })
  .catch(() => false);
