import { get, post, ResponseResult } from '@shared/tandon-plugin/request';
import { getWeworkTenantId } from '@shared/tandon-plugin/token';
import {
  ICountRoleAndLicenceResult,
  DescribeQueuesCntParams,
  DescribeQueuesCntResult,
  GetTXCUrlResult,
} from './user.type';

const userBaseURL = '/api/v1';

/**
 * 查询license使用情况
 * @param
 * @wiki http://doc.tandon.woa.com/project/38/interface/api/1633
 * @returns
 */
export const getCountRoleAndLicence = (): Promise<ResponseResult<ICountRoleAndLicenceResult>> => post(`${userBaseURL}/user/CountRoleAndLicence`, { });
/**
 * 查询队列列表(包括用户数量)
 * @param GetDisable：选填，0: 默认值，只返回生效的，1: 返回包括已经禁用的，OnlyEmpty：选填，0: 默认值, 返回所有符合条件的，1: 只返回空队列信息，QueueIds，SortField，SortOrder，TenantId
 * @wiki http://docs.cloudrpa.woa.com/project/46/interface/api/5509
 * @returns
 */
export const describeQueuesCnt = (params: DescribeQueuesCntParams): Promise<ResponseResult<DescribeQueuesCntResult[]>> => post(`${userBaseURL}/user/DescribeQueuesCnt`, { TenantId: getWeworkTenantId(), ...params });

/**
 * 获取吐小曹登录密钥
 */
export const getTxcUserData = (): Promise<GetTXCUrlResult> => get(`${userBaseURL}/user/GetTXCUrl`);
