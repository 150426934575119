// 请选择提供服务支持团队的人数
export const ACCOUNT_OPTIONS = [
  { label: '1-5人', value: 1 },
  { label: '6-10人', value: 2 },
  { label: '11-50人', value: 3 },
  { label: '50人以上', value: 4 },
]; 

// 请选择您的使用场景
export const SCENE_OPTIONS = [
  {
    'OptionLabel': '对客户提供服务支持（如：售前咨询、售后支持等场景）',
    'OptionKey': 1,
    'OptionIconsUrl': [
      'https://filecache.test-andon.tencent.com/andon-fe/common/new-user-travel/address-book-grey.svg'
    ],
    'OptionIconsUrlBlue': [
      'https://filecache.test-andon.tencent.com/andon-fe/common/new-user-travel/address-book-blue.svg'
    ]
  },
  {
    'OptionLabel': '对公司内部提供服务支持（如：员工IT咨询等场景）',
    'OptionKey': 2,
    'OptionIconsUrl': [
      'https://filecache.test-andon.tencent.com/andon-fe/common/new-user-travel/building-skyscraper-grey.svg'
    ],
    'OptionIconsUrlBlue': [
      'https://filecache.test-andon.tencent.com/andon-fe/common/new-user-travel/building-skyscraper-blue.svg'
    ]
  },
  {
    'OptionLabel': '两者都有',
    'OptionKey': 0,
    'OptionIconsUrl': [
      'https://filecache.test-andon.tencent.com/andon-fe/common/new-user-travel/address-book-grey.svg',
      'https://filecache.test-andon.tencent.com/andon-fe/common/new-user-travel/building-skyscraper-grey.svg'
    ],
    'OptionIconsUrlBlue': [
      'https://filecache.test-andon.tencent.com/andon-fe/common/new-user-travel/address-book-blue.svg',
      'https://filecache.test-andon.tencent.com/andon-fe/common/new-user-travel/building-skyscraper-blue.svg'
    ]
  }
]