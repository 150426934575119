import React from 'react';
import { LoadingIcon } from 'tdesign-icons-react';
import { Loading } from 'tdesign-react';
import s from './index.module.scss';

export default function MicroAppDiv(): React.ReactElement {
  const timeOrigin = performance.now();
  return (
    // eslint-disable-next-line react/no-unknown-property
    <div id="micro-frontend-root" className="micro-frontend-container" time-origin={timeOrigin}>
      <div className={s.microAppLoading}>
        <Loading indicator={<LoadingIcon />} loading size="medium" />
      </div>
    </div>
  );
}
