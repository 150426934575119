import { get, post } from '../request';
import { ResponseResult } from '../interfaces';

interface IgetSignatureCompanyParams {
  NonceStr: string;
  Timestamp: number;
  AuthCorpId: string;
  PageUrl: string;
}

interface IgetSignatureCompanyResult {
  Signature: string;
}

interface IgetSignatureAppResult {
  Signature: string;
  AgentId: number;
}

interface IweworkRefreshTokenResult {
  AccessToken: string;
  RefreshToken: string;
}

/**
 * 获取jssdk企业签名
 * @returns
 */
export const getSignatureCompany = (params: IgetSignatureCompanyParams): Promise<ResponseResult<IgetSignatureCompanyResult>> => get(`/wework/api/signature/company?nonceStr=${params.NonceStr}&timestamp=${params.Timestamp}&authCorpId=${params.AuthCorpId}&pageUrl=${params.PageUrl}`);

/**
 * 获取jssdk企业签名
 * @returns
 */
export const getSignatureCompanyPost = (params: IgetSignatureCompanyParams): Promise<ResponseResult<IgetSignatureCompanyResult>> => post('/wework/api/signature/company', {
  NonceStr: params.NonceStr,
  Timestamp: `${params.Timestamp}`,
  AuthCorpId: params.AuthCorpId,
  PageUrl: params.PageUrl,
});

/**
 * 获取jssdk应用签名
 * @returns
 */
export const getSignatureApp = (params: IgetSignatureCompanyParams): Promise<ResponseResult<IgetSignatureAppResult>> => get(`/wework/api/signature/app?nonceStr=${params.NonceStr}&timestamp=${params.Timestamp}&authCorpId=${params.AuthCorpId}&pageUrl=${params.PageUrl}`);

/**
 * 获取jssdk应用签名
 * @returns
 */
export const getSignatureAppPost = (params: IgetSignatureCompanyParams) => post('/wework/api/signature/app', {
  NonceStr: params.NonceStr,
  Timestamp: `${params.Timestamp}`,
  AuthCorpId: params.AuthCorpId,
  PageUrl: params.PageUrl,
});

/**
 * token续期
 * api http://docs.cloudrpa.woa.com/project/46/interface/api/5156
 * @returns
 */
export const weworkRefreshToken = (refreshToken: string): Promise<ResponseResult<IweworkRefreshTokenResult>> => post('/wework/login/refresh/token', {
  RefreshToken: refreshToken,
});
