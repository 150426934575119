import React from 'react';
import { DEFAULT_SYSTEM_FIELDS } from '../constants';
import { getFieldList, saveFieldList } from '../api';
import { TemplateField } from '../type';

export type FieldManager = {
  key: number; // 用于刷新组件
  list: TemplateField[]; // 模板字段
  loading: boolean; // 正在请求
  loaded: boolean; // 首次请求完毕
  error: boolean; // 请求出错
  save(newValue: TemplateField[]): Promise<boolean>; // 保存
  refetch(): void; // 重新请求
};

type Options = {
  excludeSystem: boolean; // 过滤掉系统字段
};

const defaultOptions: Options = {
  excludeSystem: false,
};

/** 获取所有模板字段 */
export default function useFieldManager(options = {} as Partial<Options>): FieldManager {
  const finalOptions = { ...defaultOptions, ...options };

  const [key, setKey] = React.useState(0);

  const [list, setList] = React.useState<TemplateField[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false); // 接口出错，无法拉取到数据，禁止编辑
  const [loaded, setLoaded] = React.useState(false);

  const fetchData = () => {
    setLoading(true);
    getFieldList(true)
      .then((data) => {
        setList(mergeWithDefault(data, DEFAULT_SYSTEM_FIELDS).filter(_ => !finalOptions.excludeSystem || !_.isSystem));
        setKey(+new Date());
        setLoaded(true);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  React.useEffect(fetchData, []);

  const save = async (newValue: TemplateField[]) => {
    setLoading(true);
    const ok = await saveFieldList(newValue).atleast(400);
    if (ok) setList(mergeWithDefault(newValue, DEFAULT_SYSTEM_FIELDS).filter(_ => !finalOptions.excludeSystem || !_.isSystem));
    setLoading(false);
    return ok;
  };

  return { key, list, loading, loaded, error, save, refetch: fetchData };
}

/**
 * 合并兜底的系统字段
 */
function mergeWithDefault(
  remoteFields: TemplateField[], // 远端的字段池
  defaultSystemFields: TemplateField[], // 兜底的系统字段池
) {
  const map: Map<string, TemplateField> = new Map();

  remoteFields.forEach(f => map.set(f.id, f));

  defaultSystemFields.forEach((f) => {
    if (!map.get(f.id)) map.set(f.id, f);
  });

  // @ts-ignore
  return [...map.values()];
}
