export const PLACEHOLDER = '-';

export const MESSAGE_DURING_TIME = 2 * 1000;

export enum ServiceStatusEnum {
  normal = 0, // 套餐状态正常
  expired = 1, // 过期
  termination = 2, // 手动终止
  nonactivated = 3, // 没有开通套餐
}

export const STUFF_URL = 'wxwork://message?uin=1688854804393209';
