import React from 'react';
import { Input as TDInput } from 'tdesign-react';
import { TemplateField } from '../type';

type Props = {
  sensitivity: TemplateField['props']['sensitivity'];

  disabled: boolean;
  value: string;
  onChange(value: string): any;
  [other: string]: any;
};

/** 【单行文本】字段编辑组件 */
const Input: React.FC<Props> = ({ sensitivity, ...rest }) => {
  const clearOnce = React.useRef(sensitivity?.enable === true);
  return (
    <TDInput
      {...rest}
      onFocus={(...args: any[]) => {
        if (clearOnce.current) {
          clearOnce.current = false;
          rest.onChange('');
        }
        rest.onFocus?.(...args);
      }}
    />
  );
};

export default Input;
