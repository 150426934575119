import React, { useState, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { ServiceStatusEnum } from '@/constants';
import { NORMAL_ROUTES, EXPIRED_ROUTES, TERMINATION_ROUTES, ONACTIVATED_ROUTES } from './routes';

interface IRouterProps {
  serviceStatus: ServiceStatusEnum;
  isTrial: boolean;
}

export default function Router(props: IRouterProps): React.ReactElement {
  const { serviceStatus, isTrial } = props;

  const [initRoutes, setInitRoutes] = useState(NORMAL_ROUTES);

  const Routers = useRoutes(initRoutes);

  useEffect(() => {
    // 套餐状态正常（试用和正式）
    if (serviceStatus === ServiceStatusEnum.normal) {
      setInitRoutes(NORMAL_ROUTES);
    } else if (serviceStatus === ServiceStatusEnum.expired) {
      // 试用版过期也是进入到只有落地页的路由
      if (isTrial) {
        setInitRoutes(ONACTIVATED_ROUTES);
      // 正式版过期
      } else {
        setInitRoutes(EXPIRED_ROUTES);
      }
    // 手动终止套餐
    } else if (serviceStatus === ServiceStatusEnum.termination) {
      setInitRoutes(TERMINATION_ROUTES);
    // 没开通过套餐
    } else if (serviceStatus === ServiceStatusEnum.nonactivated) {
      setInitRoutes(ONACTIVATED_ROUTES);
    }
  }, [serviceStatus, isTrial]);

  return <div className="main-routers-container">{Routers}</div>;
}
